import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MobileHamburger = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSlowmoActive, setIsSlowmoActive] = useState(false);

  useEffect(() => {
    const handleMenuToggle = () => {
      setIsMenuOpen((prev) => !prev);
    };

    const handleSlowmoToggle = () => {
      setIsSlowmoActive((prev) => !prev);
    };

    const burger = document.getElementById("menu-toggle");
    const nav = document.getElementById("custom-main-nav");
    const slowmo = document.getElementById("slowmo-toggle");

    if (burger) {
      burger.addEventListener("click", handleMenuToggle);
    }

    if (slowmo) {
      slowmo.addEventListener("click", handleSlowmoToggle);
    }

    return () => {
      if (burger) {
        burger.removeEventListener("click", handleMenuToggle);
      }

      if (slowmo) {
        slowmo.removeEventListener("click", handleSlowmoToggle);
      }
    };
  }, []);

  return (
    <div className="show-mobile">
      <div className="custom-device">
        <div className="custom-container">
          <button
            id="menu-toggle"
            className={isMenuOpen ? "open-main-nav is-open" : "open-main-nav"}
          >
            <span className="menu-icon"></span>
            <span className="menu-text">Menu</span>
          </button>
          <nav
            className={
              isMenuOpen ? "custom-main-nav is-open" : "custom-main-nav"
            }
            id="custom-main-nav"
          >
            <a style={{ marginTop: "60px" }} href={"https://guide.telemit.com"}>
              <b className="my-lbutton-10">Guide</b>
            </a>
            <a  href={"https://guide.telemit.com"}>
              <b className="my-lbutton-10">Use Cases</b>
            </a>
            <a  href={"https://guide.telemit.com"}>
              <b className="my-lbutton-10">Company</b>
            </a>
            <Link to={"/pricing"}>
              <b className="my-lbutton-10">Pricing</b>
            </Link>

            <a
              style={{ margin: "15px" }}
              href="https://calendly.com/paulw-zhrz/30min"
              target="blank"
            >
              <button className="my-lbutton">Book a Demo</button>
            </a>

            <Link>
              <button className="my-lbutton">Login</button>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MobileHamburger;
