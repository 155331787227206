import { Input, Modal, Spin, message } from "antd";
import React, { useState } from "react";
import { FaRedo } from "react-icons/fa";

export default function Redo({ row }) {
  const [showC, setShowC] = useState();
  const [loading, setloading] = useState(false);

  const adjustTime = (t) => {
    const newDate = new Date();
    newDate.setMinutes(newDate.getMinutes() + 15);
    let t2 = Number(t) + 15;

    const endDate = new Date();
    endDate.setMinutes(endDate.getMinutes() + t2);

    setShowC({
      ...showC,

      date: newDate,
      since: newDate,
      till: endDate,
      time_length: t,
    });
  };

  const savePlay = (row) => {
    delete row._id;
    row.status = "pending";
    row.till = formatDate(showC.till);
    row.since = formatDate(showC.since);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`https://embed.axv.ai/api/feed`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
          message.success("Added");
          setShowC()
        } else if (response.msgError === "Insufficient credits") {
          //   setShowPrice(true);
        } else {
          message.error("something went wrong");
        }
      });
  };

  const formatDate = (inputDate) => {
    // Parse the input date string
    const date = new Date(inputDate);

    // Extract year, month, day, hour, and minute from the parsed date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");

    // Construct the desired date string in the format "YYYY-MM-DDTHH:MM:SS"
    const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:00`;

    return formattedDate;
  };
  return (
    <>
      <FaRedo
        class="c3-svg "
        onClick={() =>
          setShowC({
            ...row,
            title: undefined,
            instruction: undefined,
            date: undefined,
            since: undefined,
            till: undefined,
            time_length: undefined,
          })
        }
      />
      <Modal
        open={showC !== undefined}
        onCancel={() => setShowC()}
        width={500}
        className="no-buttons-modal black-modal"
      >
        <h5>Run Machine Vision</h5>
        {showC ? (
          <div className=" form-pf">
            <div className="game-show-p1">
              <div>
                <label
                  style={{ margin: "20px 0px" }}
                  for="name"
                  class="label-pf"
                >
                  <span class="title-pf">Name *</span>
                  <input
                    placeholder="Name"
                    onChange={(e) => {
                      setShowC({
                        ...showC,

                        title: e.target.value,
                      });
                    }}
                    value={showC.title}
                    style={{ width: "100%" }}
                    class="input-field-pf"
                  />
                </label>
                <label
                  style={{ margin: "20px 0px" }}
                  for="name"
                  class="label-pf"
                >
                  <span class="title-pf">Instruction *</span>
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    placeholder="Instruction"
                    onChange={(e) => {
                      setShowC({
                        ...showC,

                        instruction: e.target.value,
                      });
                    }}
                    value={showC.instruction}
                    style={{ width: "100%" }}
                    size="large"
                  />
                </label>
                <label style={{ width: "100%" }} for="time" class="label-pf">
                  <span class="title-pf">Time</span>
                  <select
                    class="input-field-pf"
                    onChange={(e) => {
                      adjustTime(e.target.value);
                    }}
                    value={showC.time_length}
                  >
                    <option value="">Select Time</option>
                    <option value={15}>15 Minutes</option>
                    <option value={30}>30 Minutes</option>
                    <option value={60}>60 Minutes</option>
                    <option value="Custom">Custom</option>
                  </select>
                </label>
              </div>
            </div>
            <div className="flex-end">
              {showC.instruction &&
              showC.instruction.length &&
              showC.title &&
              showC.title.length &&
              showC.time_length ? (
                <div className="save-btn-pl">
                  {loading ? (
                    <Spin />
                  ) : (
                    <div
                      onClick={() => {
                        setloading(true);
                        savePlay(showC);
                      }}
                      className="hover-card-b"
                      style={{
                        maxWidth: "300px",
                        marginTop: "20px",
                      }}
                    >
                      Play
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
}
