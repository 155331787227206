import React, { useContext, useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import SearchContext from "../../../Context/SearchContext";
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Slider,
  Spin,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { PiBrowsersBold } from "react-icons/pi";
import { ImMobile } from "react-icons/im";
import { FaArrowLeft, FaDesktop } from "react-icons/fa";
import { TbDrone } from "react-icons/tb";
import { RiLiveFill } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import { LoadingOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
export default function PlayForm() {
  const {
    user,
    gamesData,
    setGamesData,
    showPrice,
    setShowPrice,
    organization,
  } = useContext(SearchContext);
  const [loading, setloading] = useState(false);
  const [changeActive, setChangeActive] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (changeActive) {
      setGamesData({
        ...gamesData,
        activeData: changeActive,
      });
    }
  }, [changeActive]);

  const formatDate = (inputDate) => {
    // Parse the input date string
    const date = new Date(inputDate);

    // Extract year, month, day, hour, and minute from the parsed date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");

    // Construct the desired date string in the format "YYYY-MM-DDTHH:MM:SS"
    const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:00`;

    return formattedDate;
  };
  const savePlay = (row) => {
    const obj = {
      user_id: user?.user_id,
      username: row.username,
      date: formatDate(gamesData.showPlay.date),
      game: row.value,
      file_url: row.url,
      status: "pending",
      runtime: gamesData.showPlay.runtime,
      route:
        gamesData.showPlay.route && gamesData.showPlay.route.length
          ? gamesData.showPlay.route
          : uuidv4(),
      feed_type: gamesData.gameCard,
      session: gamesData.showPlay.session,
      link: gamesData.showPlay.link,
      channelUuid: gamesData.showPlay.channelUuid,
      description: gamesData.showPlay.description,
      media_type: gamesData.showPlay.media_type,
      title: gamesData.showPlay.title,
      access: gamesData.showPlay.access,
      instruction: gamesData.showPlay.instruction,
      demographic_country: gamesData.showPlay.demographic_country,
      demographic_gender: gamesData.showPlay.demographic_gender,
      multi_start: gamesData.showPlay.multi_start,
      multi_end: gamesData.showPlay.multi_end,
      multi_days: gamesData.showPlay.multi_days,
      model: gamesData.showPlay.model,
      platform: gamesData.showPlay.platform,
      time_length: gamesData.showPlay.time_length,
      recording_type: gamesData.showPlay.recording_type,
      steam_key: gamesData.showPlay.steam_key,
      till: formatDate(gamesData.showPlay.till),
      since: formatDate(gamesData.showPlay.since),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
          message.success("Added");
          if (gamesData.activeData) {
            // let all = [response.dataStream, ...gamesData.activeData];

            setGamesData({
              ...gamesData,
              // activeData: all,
              showPlay: undefined,
              gameCard: undefined,
              activeData: undefined,
              tableData: undefined,
              oldData: undefined,
              tabType: "live",
              callPost: gamesData.callPost ? gamesData.callPost + 1 : 1,
            });
          } else {
            setGamesData({
              ...gamesData,

              showPlay: undefined,
              gameCard: undefined,
              activeData: undefined,
              oldData: undefined,
              tableData: undefined,
              tabType: "live",
              callPost: gamesData.callPost ? gamesData.callPost + 1 : 1,
            });
          }
        } else if (response.msgError === "Insufficient credits") {
          setShowPrice(true);
        } else {
          message.error("something went wrong");
        }
      });
  };
  const changePage = (data, session) => {
    if (session === "multi_session" || session === "longitudinal_study") {
      navigate(`/live/sessions/${data._id}`);
    } else if (session === "single_session") {
      navigate("/live");
    }
  };
  const updatePlay = (row) => {
    const obj = {
      username: row.username,
      date: row.date,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isPatched) {
          message.success("Pushed");

          let all = gamesData.activeData.map((m) =>
            m._id === row._id ? response.patchedData : m
          );
          setChangeActive(all);
        } else {
          message.error("something went wrong");
        }
      });
  };
  const adjustTime = (t) => {
    const newDate = new Date();
    newDate.setMinutes(newDate.getMinutes() + 20);
    let t2 = Number(t) + 20;

    const endDate = new Date();
    endDate.setMinutes(endDate.getMinutes() + t2);
    console.log(newDate, endDate);
    setGamesData({
      ...gamesData,
      showPlay: {
        ...gamesData.showPlay,
        date: newDate,
        since: newDate,
        till: endDate,
        time_length: t,
      },
    });
  };

  const secondStep = () => {
    return (
      <div className="simu-main-cards-c">
        <Row>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            xl={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
            style={{ padding: "15px" }}
          >
            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  showPlay: {
                    ...gamesData.showPlay,
                    step: gamesData.showPlay.step + 1,
                    recording_type: "live",
                  },
                });
              }}
              className="hover-card-b"
            >
              <div>
                <div style={{ marginBottom: "0px" }} className="simu-card-t-i">
                  <RiLiveFill color="lime" />
                  <b>Live Recording</b>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            xl={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
            style={{ padding: "15px" }}
          >
            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  showPlay: {
                    ...gamesData.showPlay,
                    step: gamesData.showPlay.step + 1,
                    recording_type: "non_recorded",
                  },
                });
              }}
              className="hover-card-b"
            >
              <div>
                <div style={{ marginBottom: "0px" }} className="simu-card-t-i">
                  <RiLiveFill color="#ff7272" />
                  <b>No Recording</b>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const firstStep = () => {
    return (
      <div className="simu-main-cards-c">
        <Row>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            xl={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            style={{ padding: "15px" }}
          >
            {" "}
            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  gameCard: "mobile",
                  showPlay: {
                    ...gamesData.showPlay,
                    step: gamesData.showPlay.step + 1,
                  },
                });
              }}
              className="hover-card-b"
            >
              <div>
                <div className="simu-card-t-i">
                  <ImMobile />
                  <b>Mobile</b>
                </div>

                <div className="simu-card-t-des">
                  Run AI live environments on mobile operating systems including
                  Android and iOS that can download and interact with apps
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            xl={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            style={{ padding: "15px" }}
          >
            {" "}
            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  gameCard: "desktop",
                  showPlay: {
                    ...gamesData.showPlay,
                    step: gamesData.showPlay.step + 1,
                  },
                });
              }}
              className="hover-card-b"
            >
              <div>
                <div className="simu-card-t-i">
                  <FaDesktop />
                  <b>Desktop</b>
                </div>

                <div className="simu-card-t-des">
                  Simulate live desktop environments across Windows and Linux
                  that can play video games or interact with desktop apps
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            xl={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            style={{ padding: "15px" }}
          >
            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  gameCard: "browser",
                  showPlay: {
                    ...gamesData.showPlay,
                    step: gamesData.showPlay.step + 1,
                  },
                });
              }}
              className="hover-card-b"
            >
              <div>
                <div className="simu-card-t-i">
                  <PiBrowsersBold />
                  <b>Tablet</b>
                </div>

                <div className="simu-card-t-des">
                  Deploy simulated live Tablet environments that can interact
                  with the web including Chrome, Edge and Firefox.
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            xl={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            style={{ padding: "15px" }}
          >
            {" "}
            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  gameCard: "connected_device",
                  showPlay: {
                    ...gamesData.showPlay,
                    step: gamesData.showPlay.step + 1,
                  },
                });
              }}
              className="hover-card-b"
            >
              <div>
                <div className="simu-card-t-i">
                  <TbDrone />
                  <b>Connected Device</b>
                </div>

                <div className="simu-card-t-des">
                  Embed AI and create parallel environments in third party
                  connected devices including drones, and devices
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div className="container">
      {gamesData.showPlay ? (
        <div>
          <h4 className="gradient-text-title" style={{ textAlign: "center" }}>Run Machine Vision</h4>

          {/* <div className="home-input-c-c form-pf">
            <div className="parameters-d-cli">
              <span />

              <div className="close-icon">
                <MdClose
                  onClick={() => {
                    setGamesData({
                      ...gamesData,
                      showPlay: undefined,
                      gameCard: undefined,
                    });
                  }}
                />
              </div>
            </div>
          </div> */}

          <div style={{ minHeight: "500px" }} className=" form-pf">
            {gamesData.showPlay.step > 1 ? (
              <FaArrowLeft
                onClick={() => {
                  setGamesData({
                    ...gamesData,
                    showPlay: {
                      ...gamesData.showPlay,
                      step: gamesData.showPlay.step - 1,
                    },
                  });
                }}
                className="back-arrow-form"
              />
            ) : null}
            <div className="game-show-p1">
              <div>
                {gamesData.showPlay.step === 1 ? firstStep() : null}
                {gamesData.showPlay.step === 2 ? secondStep() : null}
                {gamesData.showPlay.step === 3 ? (
                  <div>
                    <div>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="name"
                        class="label-pf"
                      >
                        <span class="title-pf">Name *</span>
                        <input
                          placeholder="Name"
                          onChange={(e) => {
                            setGamesData({
                              ...gamesData,
                              showPlay: {
                                ...gamesData.showPlay,
                                title: e.target.value,
                              },
                            });
                          }}
                          value={gamesData.showPlay.title}
                          style={{ width: "100%" }}
                          class="input-field-pf"
                        />
                      </label>
                      <div className="together-fields-c">
                        <label
                          style={{ width: "100%" }}
                          for="Model"
                          class="label-pf"
                        >
                          <span class="title-pf">Model *</span>
                          <select
                            class="input-field-pf"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                showPlay: {
                                  ...gamesData.showPlay,
                                  model: e.target.value,
                                  platform: undefined,
                                  steam_key:undefined,
                                },
                              });
                            }}
                            value={gamesData.showPlay.model}
                          >
                            <option value="">Select Model</option>
                            <option value="gaming">Gaming</option>
                            <option value="software">Software</option>
                            <option value="ecommerce">Ecommerce</option>
                            <option value="education">Education</option>
                            <option value="custom">Custom</option>
                          </select>
                        </label>
                        <label
                          style={{ width: "100%", marginLeft: "10px" }}
                          for="session"
                          class="label-pf"
                        >
                          <span class="title-pf">Session</span>
                          <select
                            class="input-field-pf"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                showPlay: {
                                  ...gamesData.showPlay,
                                  session: e.target.value,
                                },
                              });
                            }}
                            value={gamesData.showPlay.session}
                          >
                            <option value="">Select Session</option>
                            <option value="single_session">
                              Single Session
                            </option>
                            <option value="multi_session">Multi-Session</option>
                            <option value="longitudinal_study">
                              Longitudinal Study
                            </option>
                          </select>
                        </label>
                      </div>
                      {gamesData.showPlay.model === "gaming" ? (
                        <label
                          style={{ margin: "20px 0px" }}
                          for="Platform"
                          class="label-pf"
                        >
                          <span class="title-pf">Platform</span>
                          <select
                            class="input-field-pf"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                showPlay: {
                                  ...gamesData.showPlay,
                                  platform: e.target.value,
                                },
                              });
                            }}
                            value={gamesData.showPlay.platform}
                          >
                            <option value={undefined}>Select Platform</option>

                            <>
                              <option value="epic_Store">Epic Store</option>
                              <option value="steam">Steam</option>
                              <option value="none">None</option>
                            </>
                          </select>
                        </label>
                      ) : null}
                      {gamesData.showPlay.model === "gaming" &&
                      gamesData.showPlay.platform === "steam" ? (
                        <label
                          style={{ margin: "20px 0px" }}
                          for="steam_key"
                          class="label-pf"
                        >
                          <span class="title-pf">Steam Key</span>
                          <input
                            placeholder=" Steam Key"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                showPlay: {
                                  ...gamesData.showPlay,
                                  steam_key: e.target.value,
                                },
                              });
                            }}
                            class="input-field-pf"
                            value={gamesData.showPlay.steam_key}
                            size="large"
                          />
                        </label>
                      ) : null}
                      <div
                        className="together-fields-c"
                        style={{ margin: "20px 0px" }}
                      >
                        <label
                          style={{ width: "100%", marginRight: "10px" }}
                          for="name"
                          class="label-pf"
                        >
                          <span class="title-pf"> Link</span>

                          <input
                            placeholder=" Link"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                showPlay: {
                                  ...gamesData.showPlay,
                                  link: e.target.value,
                                },
                              });
                            }}
                            class="input-field-pf"
                            value={gamesData.showPlay.link}
                            size="large"
                          />
                        </label>

                        <label for="surface" class="label-pf">
                          <span class="title-pf">Surface</span>
                          <select
                            class="input-field-pf"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                showPlay: {
                                  ...gamesData.showPlay,
                                  channelUuid: e.target.value,
                                },
                              });
                            }}
                            value={gamesData.showPlay.channelUuid}
                          >
                            <option disabled value="">
                              Select Surface
                            </option>

                            <option value="chrome">Chrome</option>
                            <option value="edge">Edge</option>
                            <option value="android">Android</option>
                            <option value="firefox">Firefox</option>
                            <option value="safari">Safari</option>
                            <option value="apple">Apple</option>
                            <option value="windows">Windows</option>
                          </select>
                        </label>
                      </div>

                      <div>
                        <label
                          style={{ margin: "20px 0px" }}
                          for="name"
                          class="label-pf"
                        >
                          <span class="title-pf">Instruction *</span>
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            placeholder="Instruction"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                showPlay: {
                                  ...gamesData.showPlay,
                                  instruction: e.target.value,
                                },
                              });
                            }}
                            value={gamesData.showPlay.instruction}
                            style={{ width: "100%" }}
                            size="large"
                          />
                        </label>

                        <label
                          style={{ margin: "20px 0px" }}
                          for="access"
                          class="label-pf"
                        >
                          <span class="title-pf">Access</span>
                          <select
                            class="input-field-pf"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                showPlay: {
                                  ...gamesData.showPlay,
                                  access: e.target.value,
                                },
                              });
                            }}
                            value={gamesData.showPlay.access}
                          >
                            <option value="">Select Access</option>
                            <option value="public">Public</option>
                            <option value="private">Private</option>
                          </select>
                        </label>

                        <div
                          className="together-fields-c"
                          style={{ margin: "20px 0px" }}
                        >
                          <label
                            style={{ width: "100%" }}
                            for="time"
                            class="label-pf"
                          >
                            <span class="title-pf">Time *</span>
                            <div className="biling-m-ci1">
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="biling-m-c1 my-sli-play-f"
                              >
                                <Slider
                                  min={15}
                                  max={420}
                                  onChange={adjustTime}
                                  value={gamesData.showPlay.time_length}
                                  style={{ width: "100%" }}
                                />
                                <b>{gamesData.showPlay.time_length} Minutes</b>
                              </div>
                              {/* <div className="bottom-txt-sli">
                                <b>15</b>
                                <b>420 min</b>
                              </div> */}
                            </div>
                          </label>
                        </div>

                        {gamesData.showPlay.session === "multi_session" ||
                        gamesData.showPlay.session === "longitudinal_study" ? (
                          <div>
                            <div className="date-session-c">
                              <div>
                                <span style={{ marginRight: "10px" }}>
                                  Start
                                </span>
                                <DatePicker
                                  format="MM-DD-YYYY"
                                  value={gamesData.showPlay.multi_start}
                                  onChange={(e) =>
                                    setGamesData({
                                      ...gamesData,
                                      showPlay: {
                                        ...gamesData.showPlay,
                                        multi_start: e,
                                      },
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <span style={{ marginRight: "10px" }}>End</span>
                                <DatePicker
                                  format="MM-DD-YYYY"
                                  value={gamesData.showPlay.multi_end}
                                  onChange={(e) =>
                                    setGamesData({
                                      ...gamesData,
                                      showPlay: {
                                        ...gamesData.showPlay,
                                        multi_end: e,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="date-session-c2">
                              <span style={{ marginRight: "10px" }}>Days</span>
                              <InputNumber
                                min={2}
                                max={365}
                                value={gamesData.showPlay.multi_days}
                                onChange={(e) => {
                                  setGamesData({
                                    ...gamesData,
                                    showPlay: {
                                      ...gamesData.showPlay,
                                      multi_days: e,
                                    },
                                  });
                                }}
                                placeholder="Days"
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}

                {gamesData.showPlay.step === 3 ? (
                  <div className="space-b-btns">
                    <div />

                    <div className="save-btn-pl">
                      {loading ? (
                        <Spin indicator={antIcon} />
                      ) : gamesData.showPlay.model &&
                        gamesData.showPlay.model.length &&
                        gamesData.showPlay.title &&
                        gamesData.showPlay.title.length &&
                        gamesData.showPlay.instruction &&
                        gamesData.showPlay.instruction.length &&
                        gamesData.showPlay.time_length ? (
                        <div
                          onClick={() => {
                            // setloading(true);
                            // setGamesData({
                            //   ...gamesData,
                            //   showPlay: undefined,
                            // });
                            setloading(true);
                            savePlay(gamesData.showPlay);
                          }}
                          className="hover-card-b"
                          style={{
                            maxWidth: "300px",
                          }}
                        >
                          Play
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
