import Home from "./home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Login from "./login/Login";
import Signup from "./signup/Signup";
import { useContext, useEffect, useState } from "react";
import SearchContext from "../Context/SearchContext";
import FullLoader from "../components/FullLoader";

import Vision from "./vision/Vision";
import SimulationVideo from "./simulationVideo/SimulationVideo";
import WatchVideo from "./watchVideo/WatchVideo";
import PricingPage from "./pricing/PricingPage";
import Public from "./public/Public";
import "../responsive.css";
import OnboardFeed from "./onboardFeed/OnboardFeed";
//
function Pages() {
  const navigate = useNavigate();
  const { setUser, loginChange, setTheme, setOrganization, setLanguage, user } =
    useContext(SearchContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // let test  = "[{\"col\": 12,\"id\": \"s1\",\"style\": { \"background\": \"#e1bc9c\", \"padding\": \"10px\" },\"element\": \"div\",\"data\": [{\"element\": \"div\",\"col\": 3,\"style\": { \"background\": \"#e8bc1d\", \"padding\": \"10px\" },\"data\": [{\"element\": \"input\",\"type\": \"text\",\"style\": {},\"placeholder\": \"Enter your cat's name\"},{\"element\": \"button\",\"text\": \"Submit\",\"style\": { \"color\": \"red\" }},{\"element\": \"img\",\"src\": \"https://storage.googleapis.com/download/storage/v1/b/fussydata/o/9862eb54b73cfbae4a77b209e7e5f686.png?generation=1703878711546592&alt=media\",\"alt\": \"Cute cat image\",\"style\": { \"width\": \"100px\" }},{\"element\": \"a\",\"href\": \"https://techcrunch.com/\",\"target\": \"_blank\",\"text\": \"Learn more about cats\",\"style\": {}},{\"element\": \"p\",\"text\": \"Welcome to the cat page\",\"style\": { \"fontSize\": \"16px\" }},{\"element\": \"h1\",\"text\": \"All About Cats\",\"style\": {}},{\"element\": \"h2\",\"text\": \"Cat Breeds\",\"style\": {}}]}]},{\"col\": 12,\"id\": \"s2\",\"style\": { \"background\": \"#e1bc9c\", \"padding\": \"10px\" },\"element\": \"div\",\"data\": [{\"element\": \"div\",\"col\": 3,\"style\": { \"background\": \"#e8bc1d\", \"padding\": \"10px\" },\"data\": [{\"element\": \"button\",\"text\": \"Back to Home\",\"style\": { \"color\": \"red\" }}]}]}]"

    // const jsonObject = JSON.parse(test);
    // console.log(jsonObject);
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      setUser(u);
      getOrganization(u);
    } else {
      if (
        window.location.pathname !== "/" &&
        window.location.pathname.includes("/onboard") === -1 &&
        window.location.pathname !== "/pricing" &&
        window.location.pathname !== "/community" &&
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/signup"
      ) {
        navigate("/signup");
      }
      setLoading(false);
    }
  }, [loginChange]);

  useEffect(() => {
    const t = window.localStorage.getItem("theme");
    const lang = window.localStorage.getItem("language");
    if (lang) {
      setLanguage(lang);
    }
    if (t) {
      setTheme(t);
    }
  }, []);

  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/organization/${u.user_id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let org = response.organization;
          setOrganization(org);
          setLoading(false);
        }
      });
  };
  return loading ? (
    <div className="full-h-loader-d">
      <FullLoader />
    </div>
  ) : (
    <>
      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> */}
        {/* <Route path="/test" element={<div style={{background:"white"}}><MyCalender /></div>} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route
          path="/simulation/image/live/:id"
          element={<SimulationImages />}
        />

        <Route path="/live/sessions/:id" element={<LiveSessions />} />
        <Route path="/simulation" element={<Simulation />} />
        <Route path="/live" element={<Live />} /> */}
        <Route path="/" element={user ? <Vision /> : <Login />} />
        <Route path="/community" element={<Public />} />

        <Route path="/:id" element={<SimulationVideo />} />
        <Route path="/onboard/:id" element={<OnboardFeed />} />

        <Route path="/community/:id" element={<WatchVideo />} />
        <Route path="/pricing" element={<PricingPage />} />
      </Routes>
    </>
  );
}

export default Pages;
