import {
  Button,
  Drawer,
  Input,
  Popconfirm,
  Spin,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MdMessage } from "react-icons/md";
import SearchContext from "../../../Context/SearchContext";
import { LoadingOutlined } from "@ant-design/icons";
import { RiSendPlane2Fill } from "react-icons/ri";
import Call from "./Call";

export default function SimulationChat({ row }) {
  const { user, setShowPrice } = useContext(SearchContext);
  const [text, setText] = useState("");
  const [chat, setChat] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [scroll, setScroll] = useState(true);
  const [callApi, setCallApi] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    getChat();
  }, [callApi]);

  const getChat = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/chat_log?page=${pageNo}&limit=5&roleplay_id=${window.location.pathname.slice(1)}&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (data && data.isSuccess) {
          let all = [];
          let newChat = data.chatlogList.reverse();

          all = newChat.concat(chat);

          setChat(all);
          setTotalPages(data.totalPages);
          setTimeout(() => {
            if (pageNo === 1) {
              containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: "smooth",
              });
            }
            // if (scroll) {
            //   setScroll(false);
            //   scrollToElement(row._id);
            // }
          }, 100);
        } else {
          message.warning("Something went wrong");
        }
      });
  };
  function scrollToElement(elementId) {
    var element = document.getElementById(elementId);
    if (element) {
      var offsetTop = element.offsetTop;
      console.log(element.scrollHeight, offsetTop);
      element.scrollTop = element.scrollHeight;
    }
  }

  // Function to handle scrolling in the chat container
  const handleScroll = () => {
    if (
      containerRef.current.scrollTop === 0 &&
      !isLoading &&
      pageNo < totalPages
    ) {
      setIsLoading(true);
      setPageNo(pageNo + 1);
      setCallApi(callApi + 1);
      containerRef.current.scrollTop = 1;
    }
  };
  const createTask = (txt) => {
    const obj = {
      query: txt ? txt : text,
      user_id: user && user.user_id,
      roleplay_id: row._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/vector/dialogue`, requestOptions)
      .then((response) => {
        if (response.status === 429) {
          setText("");
          setLoading(false);
          setShowPrice(true);

          throw new Error("Too Many Requests (429)");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data._id) {
          saveData(data);
        } else {
          console.log("API call succeeded, but data is missing");
          message.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
        if (error.message !== "Too Many Requests (429)") {
          message.error("Something went wrong. Please try again later.");
        }
      });
  };
  const saveData = (data) => {
    let newChat = [...chat, data];
    setText("");
    setLoading(false);
    setChat(newChat);

    setTimeout(() => {
      if (pageNo === 1) {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 100);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter key behavior (e.g., adding a new line)
      createTask(); // Call the function to submit the form
      setLoading(true); // Set loading state if needed
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "#bedbff",
        marginLeft: "5px",
      }}
      spin
    />
  );
  return (
    <div className="chat-main-d">
      <Call createTask={createTask} row={row} saveData={saveData}/>
      <div
        id={row._id}
        ref={containerRef}
        onScroll={handleScroll}
        className="chat-bx-mnd"
      >
        {chat &&
          chat.map((c, ci) => {
            return (
              <div key={ci}>
                <div
                  style={{
                    display:
                      ci > 0 && chat[ci - 1].query === c.query
                        ? "none"
                        : "flex",
                  }}
                  className="user-q-chat"
                >
                  <div>
                    <p>{c.query}</p>
                  </div>
                </div>
                <div className="user-r-chat">
                  <p>{c.response}</p>
                </div>
              </div>
            );
          })}
      </div>
      <div className="chat-bx-input-c">
        <Input.TextArea
          placeholder="Type..."
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          class="input-field-pf"
          onKeyDown={handleKeyDown} // Listen for Enter key press
        />

        {loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <div
            className={`icon-left-ed   active-i-l-ed`}
            style={{ width: "34px" }}
            onClick={() => {
              if (!loading) {
                createTask();
                setLoading(true);
              }
            }}
          >
            <RiSendPlane2Fill />
          </div>
        )}
      </div>
    </div>
  );
}
