import { Input, Layout, Tooltip } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../../../Context/SearchContext";
import AddBlock from "./AddBlock";
import functionsImg from "../../../../images/Functions.png";
import { MdClose } from "react-icons/md";

const { Header, Sider, Content } = Layout;
export default function FunctionsHeader({ style }) {
  const { images, setImages, user, listData, setListData } =
    useContext(SearchContext);

  return (
    <div>
      <div className="header-cards-btn">
        <div className="help-text-con">
          <img className="top-active-img" src={functionsImg} alt="" />

          <a
            style={{ textDecoration: "none", marginRight: "10px" }}
            href="https://guide.dendric.com/engrams/skills"
            target="blank"
          >
            <Tooltip title="Help">
              <div
                style={{ backgroundColor: "#fde281" }}
                className="hover-card-b"
              >
                Guide
              </div>
            </Tooltip>
          </a>
        </div>

        <div className="filter-box-r">
          <div
            className="hover-card-b"
            style={{
              backgroundColor:
                listData.listType === undefined ? "#c1ff98" : "#fe9aa3",
              marginRight: "10px",
            }}
            onClick={() => {
              setListData({
                ...listData,

                listType: undefined,
              });
            }}
          >
            Resources
          </div>
          <div
            style={{
              backgroundColor:
                listData.listType === "Active" ? "#c1ff98" : "#fe9aa3",
              marginRight: "10px",
            }}
            className="hover-card-b"
            onClick={() => {
              setListData({
                ...listData,
                listType: "Active",
              });
            }}
          >
            Active
          </div>
          <div
            className="hover-card-b"
            style={{
              backgroundColor: listData.showFilter ? "#c1ff98" : "#fe9aa3",
            }}
            onClick={() => {
              setListData({
                ...listData,
                showFilter: true,
              });
            }}
          >
            Filter
          </div>
        </div>
      </div>
      {listData.showFilter ? (
        <div>
          <div style={{ background: "transparent" }} className="home-input-c-c">
            <div className="parameters-d-cli">
              <div />
              <div className="close-icon">
                <MdClose
                  onClick={() => {
                    setListData({
                      ...listData,
                      guideFilter: "",
                      showFilter: undefined,
                      data: undefined,
                      totalPages: undefined,
                      pageNo: 1,
                    });
                  }}
                />
              </div>
            </div>
            <div className="guide-filters">
              {[
                "models",
                "sales",
                "finance",
                "scheduling",
                "database",
                "storage",
                "communications",
                "Productivity",
              ].map((f, fi) => {
                return (
                  <div
                    key={fi}
                    className="hover-card-b"
                    style={{
                      backgroundColor:
                        listData.guideFilter === f ? "#c1ff98" : "#fe9aa3",
                    }}
                    onClick={() => {
                      setListData({
                        ...listData,
                        guideFilter: f,
                        data: undefined,
                        totalPages: undefined,
                        pageNo: 1,
                      });
                    }}
                  >
                    {f}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
