import { Badge, Input, Layout, Spin, Tooltip, message } from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import { TbBrain } from "react-icons/tb";
import { MdClose } from "react-icons/md";
import playground from "../../../images/playground.png";
import { LoadingOutlined } from "@ant-design/icons";
const { Header, Sider, Content } = Layout;
export default function PlaydroundHeader({ style }) {
  const { images, setImages, organization, user, tasks, setTasks } =
    useContext(SearchContext);
  const [search, setSearch] = useState("");
  const [loadingS, setLoadingS] = useState(false);
  const attachments = () => {
    let showImg = images.videoId
      ? images.videoId
      : images.imageId
      ? images.imageId
      : images.musicId
      ? images.musicId
      : images.soundId
      ? images.soundId
      : images.voiceId;

    let t = images.attachment?.length;
    // t = images.videoId ? t + 1 : t;
    // t = images.imageId ? t + 1 : t;
    // t = images.musicId ? t + 1 : t;
    // t = images.soundId ? t + 1 : t;
    // t = images.voiceId ? t + 1 : t;

    return t > 0 ? (
      <div className="search-bx-c-cine">
        <div className="img-active-brain">
          <Badge color="#BC1823" count={images.attachment?.length}>
            {showImg?.media_type === "image" ? (
              <img src={showImg?.file_url} alt="" />
            ) : (
              <img src={showImg?.root_image} alt="" />
            )}
          </Badge>
          <span onClick={handleClearAttachments} className="clear-icon">
            {/* Assuming you have an X icon component or an SVG for the X icon */}
            <MdClose />
          </span>
        </div>
      </div>
    ) : null;
  };
  const handleClearAttachments = () => {
    // Clear all attachments
    setImages({
      ...images,
      attachment: undefined,
      videoId: undefined,
      imageId: undefined,
      musicId: undefined,
      soundId: undefined,
      voiceId: undefined,
    });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,

        color: "black",
      }}
      spin
    />
  );
  const createTask = () => {
    const obj = {
      query: search,
      user_id: user && user.user_id,
      // video_id: images.videoId?._id,
      // image_id: images.imageId?._id,
      // music_id: images.musicId?._id,
      // sound_id: images.soundId?._id,
      // voice_id: images.voiceId?._id,
      attachment: images.attachment,
      workspace: window.location.pathname.includes("/space/")
        ? window.location.pathname.slice(7)
        : undefined,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/image/fx`, requestOptions)
      .then((response) => {
        if (response.status === 429) {
          setSearch("");
          setLoadingS(false);
          // setShowPrice(true);

          throw new Error("Too Many Requests (429)");
        }
        return response.json();
      })
      .then((data) => {
        setSearch("");
        setLoadingS(false);
        if (data.data) {
          setTasks({
            ...tasks,
            callGenerate: tasks.callGenerate ? tasks.callGenerate + 1 : 1,
          });

          success();
        } else {
          console.log("API call succeeded, but data is missing");
          message.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
        if (error.message !== "Too Many Requests (429)") {
          message.error("Something went wrong. Please try again later.");
        }
      });
  };
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content: "Processing",

      duration: 0.8,
    });
  };
  const handleSearch = () => {
    if (!loadingS && search.trim().length) {
      createTask(search);
      setLoadingS(true);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <div className="header-cards-btn">
      <div>
        <img className="top-active-img" src={playground} alt="" />
      </div>
      <div style={{ display: "flex" }} className="create-input-fvc">
        <div
          className={`create-input-fvc2  ${
            search.length > 44 ? " popup-input" : ""
          }`}
        >
          {attachments()}

          <Input.TextArea
            autoSize={{ maxRows: 4, minRows: 1 }}
            style={{
              width:
                search.length < 27
                  ? 300
                  : search.length > 35
                  ? 420
                  : search.length > 27
                  ? 350
                  : 300,
            }}
            type="text"
            placeholder="Generate"
            onChange={(e) => {
              if (e.target.value.length < 1000) {
                setSearch(e.target.value);
              }
            }}
            value={
              organization?.onboardStep === 2 || organization?.onboardStep === 3
                ? "Create video"
                : organization?.onboardStep === 7
                ? "create sound and image "
                : search
            }
            required=""
            className={`form-control border-0  px-4 grey-input main-search-cine`}
            onKeyPress={handleKeyPress}
          />
          {search.trim().length ? (
            loadingS ? (
              <div className="loader-c-sd">
                <Spin
                  style={{
                    fontSize: 20,
                    marginLeft: "10px",
                  }}
                  size="small"
                  indicator={antIcon}
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  if (!loadingS) {
                    createTask(search);
                    setLoadingS(true);
                  }
                }}
                style={{ padding: "0px", width: "auto" }}
                className={` start-card-bx `}
              >
                <div style={{ padding: "8px" }} className="icon-planing-c">
                  <TbBrain style={{ marginRight: "0px", fontSize: "24px" }} />
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>

      <div />
    </div>
  );
}
