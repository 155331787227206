import React, { useRef, useState } from "react";
import SearchContext from "./SearchContext";

const SearchState = (props) => {
  const [user, setUser] = useState();
  const [organization, setOrganization] = useState();
  const [theme, setTheme] = useState("blue");
  const [generated, setGenerated] = useState({ pageNo: 1 });
  const [createRole, setCreateRole] = useState({ pageNo: 1 });
  const [tasks, setTasks] = useState({ activeIcon: "task" });
  const [images, setImages] = useState({
    pageNo: 1,
    pageNoV: 1,
    pageNoB: 1,
    pageNo2: 1,
  });
  const [loginChange, setLoginChange] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [gamesData, setGamesData] = useState({
    pageNo: 1,
    activeTab: "Games",
    liveActive: "live",
    pageNoA: 1,
    pageNoD: 1,
    pageNoRe: 1,
    pageNoMemo:1,
    selectedDate: new Date(),
    viewType: "day",
    isCalender: true,
    tabType: "live"
  });
  const [listData, setListData] = useState({ pageNo: 1, pageNo2: 1 });
  const [behavior, setBehavior] = useState({ pageNo: 1 });
  // const [vision, setVision] = useState({ pageNo: 1, tab: "live" });

  const resetAll = () => {
    setGenerated({});
  };
  return (
    <SearchContext.Provider
      value={{
    
        gamesData,
        setGamesData,
        createRole,
        setCreateRole,
        loginChange,
        setLoginChange,
        showPrice,
        setShowPrice,
        behavior,
        setBehavior,
        theme,
        setTheme,
        generated,
        setGenerated,
        resetAll,
        tasks,
        setTasks,
        images,
        setImages,
        organization,
        setOrganization,
        user,
        setUser,
        listData,
        setListData,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
export default SearchState;
