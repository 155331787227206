import { Button, Modal, message } from "antd";
import React, { useContext, useState } from "react";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";
import SearchContext from "../../../../Context/SearchContext";

export default function AdjustStatus({ row }) {
  const {
    user,
    gamesData,
    setGamesData,

    organization,
  } = useContext(SearchContext);
  const [show, setShow] = useState();

  const updateFeed = (obj, id) => {
    delete obj._id;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isPatched) {
          let all = gamesData.rewatchData.map((c) =>
            c._id === id ? response.patchedData : c
          );

          setGamesData({
            ...gamesData,
            rewatchData: all,
          });
          message.success("Saved");
        } else {
          message.error("somehing went wrong");
        }
      });
  };
  return (
    <div>
      {row.status === "pause" ? (
        <FaPlayCircle className="play-pause-svg green-pl" onClick={() => setShow(row)} />
      ) : (
        <FaPauseCircle className="play-pause-svg yellow-pl" onClick={() => setShow(row)} />
      )}
      <Modal
        width={350}
        open={show !== undefined}
        onCancel={() => setShow()}
        className="no-buttons-modal black-modal"
      >
        <div className="bx-form-brand">
          <h6>Are you sure ?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => setShow()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                updateFeed(
                  { status: row.status === "pause" ? "pending" : "pause" },
                  show._id
                );
                setShow();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
