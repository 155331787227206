import { Col, Row } from "antd";
import React from "react";
import { TbArrowRight } from "react-icons/tb";
import "./pricing.css";
import { Link } from "react-router-dom";
import logo from "../../images/Telemit.png";

export default function PricingPage() {
  return (
    <div>
      <div style={{ padding: "20px" }} className="landing-logo-no-u">
        <Link to={"/"}>
          <img className="logo-img-bc" src={logo} alt="" />
        </Link>
        <div>
          <a
            style={{ marginRight: "10px" }}
            href="https://calendly.com/paulw-zhrz/30min"
            target="blank"
          >
            <button className="my-lbutton">Book a Demo</button>
          </a>
          <Link to={"/login"}>
            <button className="my-lbutton">Login</button>
          </Link>
        </div>
      </div>

      <div className="pricing-main-c1">
        <div className="pricing-main-c2">
          <h3>
            Pricing built for businesses <br /> of all sizes
          </h3>
          <Row>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              xl={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ padding: "10px" }}
            >
              <div className="left-p-2">
                <Row>
                  <Col span={14}>
                    <div className="left-p-21">
                      <div>
                        <h4> Standard</h4>
                        <p>
                          Telemit prices similar to how you pay for utilities
                          like electricity. Pay for the minutes when a
                          Telemission is turned on, 
                        </p>
                      </div>
                      <div>
                        <div className={`my-lbutton `}>
                          Get Started
                          <TbArrowRight />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="left-p-221">
                      <h5>$0.28/min</h5>{" "}
                      <p>each minute telemission is running</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              xl={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ padding: "10px" }}
            >
              <div className="left-p2-2">
                <Row>
                  <Col span={14}>
                    <div className="left-p-21">
                      <div>
                        <h4> Custom</h4>
                        <p>
                          Design a custom package that is tailored to your needs
                          and timeline.
                        </p>
                      </div>
                      <div>
                        <div className={`my-lbutton `}>
                          Contact Sales
                          <TbArrowRight />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="left-p2-221">
                      <h5>Fine tuned models</h5>
                      <h5>Volume discounts</h5>
                      <h5>Unlimited storage</h5>
                      <h5>Enterprise support</h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              xl={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ padding: "10px" }}
            >
              //comment
             {/* <div className="left-p-2">
                <Row>
                  <Col span={14}>
                    <div className="left-p-21">
                      <div>
                        <h4> Community</h4>
                        <p>
                          Telemit prices similar to how you pay for utilities
                          like electricity. Pay for the minutes when a
                          Telemission is turned on,
                        </p>
                      </div>
                      <div>
                        <div className={`my-lbutton `}>
                          Get Started
                          <TbArrowRight />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="left-p-221">
                      <h5>Free</h5>{" "}
                      <p>each minute telemission is running</p>
                    </div>
                  </Col>
                </Row>
              </div>*/}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
