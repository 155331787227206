import React, { useContext, useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import SearchContext from "../../../Context/SearchContext";
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Slider,
  Spin,
  message,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FaArrowLeft } from "react-icons/fa";

const { RangePicker } = DatePicker;
export default function EditForm() {
  const {
    user,
    gamesData,
    setGamesData,
    showPrice,
    setShowPrice,
    organization,
  } = useContext(SearchContext);
  const [loading, setloading] = useState(false);

  const updatePlay = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isPatched) {
          message.success("Pushed");

          let all = gamesData.rewatchData.map((m) =>
            m._id === id ? response.patchedData : m
          );
          setGamesData({
            ...gamesData,
            rewatchData: all,
            tabType: "live",
          });
        } else {
          message.error("something went wrong");
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div className="container">
      {gamesData.editForm ? (
        <div>
          <h5 style={{ textAlign: "center" }}>Edit Run Machine Vision</h5>

          <div className=" form-pf">
            <FaArrowLeft
              onClick={() =>
                setGamesData({
                  ...gamesData,
                  editForm: undefined,
                  tabType: "live",
                })
              }
              size={18}
              className=" hover"
            />
            <div className="game-show-p1">
              <div>
                <div>
                  <div>
                    <label
                      style={{ margin: "20px 0px" }}
                      for="name"
                      class="label-pf"
                    >
                      <span class="title-pf">Name *</span>
                      <input
                        placeholder="Name"
                        onChange={(e) => {
                          setGamesData({
                            ...gamesData,
                            editForm: {
                              ...gamesData.editForm,
                              title: e.target.value,
                            },
                          });
                        }}
                        value={gamesData.editForm.title}
                        style={{ width: "100%" }}
                        class="input-field-pf"
                      />
                    </label>
                    <label for="Model" class="label-pf">
                      <span class="title-pf">Model *</span>
                      <select
                        class="input-field-pf"
                        onChange={(e) => {
                          setGamesData({
                            ...gamesData,
                            editForm: {
                              ...gamesData.editForm,
                              model: e.target.value,
                              platform: undefined,
                            },
                          });
                        }}
                        value={gamesData.editForm.model}
                      >
                        <option value="">Select Model</option>
                        <option value="gaming">Gaming</option>
                        <option value="software">Software</option>
                        <option value="ecommerce">Ecommerce</option>
                        <option value="education">Education</option>
                        <option value="custom">Custom</option>
                      </select>
                    </label>
                    {gamesData.editForm.model === "gaming" ? (
                      <label
                        style={{ margin: "20px 0px" }}
                        for="Platform"
                        class="label-pf"
                      >
                        <span class="title-pf">Platform</span>
                        <select
                          class="input-field-pf"
                          onChange={(e) => {
                            setGamesData({
                              ...gamesData,
                              editForm: {
                                ...gamesData.editForm,
                                platform: e.target.value,
                              },
                            });
                          }}
                          value={gamesData.editForm.platform}
                        >
                          <option value={undefined}>Select Platform</option>

                          <>
                            <option value="epic_Store">Epic Store</option>
                            <option value="steam">Steam</option>
                            <option value="none">None</option>
                          </>
                        </select>
                      </label>
                    ) : null}

                    <div>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="name"
                        class="label-pf"
                      >
                        <span class="title-pf">Instruction *</span>
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 4 }}
                          placeholder="Instruction"
                          onChange={(e) => {
                            setGamesData({
                              ...gamesData,
                              editForm: {
                                ...gamesData.editForm,
                                instruction: e.target.value,
                              },
                            });
                          }}
                          value={gamesData.editForm.instruction}
                          style={{ width: "100%" }}
                          size="large"
                        />
                      </label>
                    </div>
                  </div>
                  <div style={{ marginTop: "40px" }} className="flex-end">
                    {loading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <div
                        onClick={() => {
                          updatePlay(
                            {
                              instruction: gamesData.editForm.instruction,
                              platform: gamesData.editForm.platform,
                              model: gamesData.editForm.model,
                              title: gamesData.editForm.title,
                            },
                            gamesData.editForm._id
                          );
                          setloading(true);
                        }}
                        className="hover-card-b"
                        style={{
                          maxWidth: "300px",
                        }}
                      >
                        Update
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
