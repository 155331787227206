import React, { useState } from "react";
import Live from "./Live";
import Memory from "./Memory";
import Public from "./Public";

export default function Telemission() {
  const [tabType, setTabType] = useState("live");
  return (
    <div>
      <div style={{ justifyContent: "center" }} className="header-cards-btn">
        <div className="filter-box-r">
          <div
            className={`hover-card-b ${
              tabType === "live" ? " selected-crd" : ""
            } `}
            onClick={() => {
              setTabType("live");
            }}
          >
            Live
          </div>
          <div
            className={`hover-card-b ${
              tabType === "memory" ? " selected-crd" : ""
            } `}
            onClick={() => {
              setTabType("memory");
            }}
          >
            Memory
          </div>

        </div>
      </div>
      <div>
        {tabType === "live" ? <Live /> : null}
        {tabType === "memory" ? <Memory /> : null}
        {/* {tabType === "public" ? <Public /> : null} */}
      </div>
    </div>
  );
}
