import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Col,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  message,
  Tabs,
  Card,
  Badge,
  Popconfirm,
} from "antd";

import { Link, useNavigate } from "react-router-dom";
import {
  FaArrowDown,
  FaArrowRight,
  FaInfoCircle,
  FaPauseCircle,
  FaPlay,
  FaPlus,
  FaRegCalendarAlt,
  FaRegCalendarCheck,
} from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

import FullLoader from "../../../components/FullLoader";
import SearchContext from "../../../Context/SearchContext";
import moment from "moment";

import {
  MdClose,
  MdDelete,
  MdGarage,
  MdLiveTv,
  MdOutlinePending,
} from "react-icons/md";
import { CgMediaLive } from "react-icons/cg";
import { PiGearSixBold } from "react-icons/pi";
// import PlusAdd from "./PlusAdd";
import Countdown from "react-countdown";
import AdjustStatus from "./components/AdjustStatus";
const surfaces = {
  firefox:
    "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/3d6a4f8f53c813e804cab34f14353e6b.webp?generation=1711656032042642&alt=media",
  chrome:
    "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/b44cac0229a04d446e175313e7a56af2.webp?generation=1711656220971887&alt=media",
  android:
    "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/a0e9abcdc7a17cfd9a23bed09bc04e2b.png?generation=1711656376081861&alt=media",
  safari:
    "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/eb1f81f5689da43bfaba3041d7b982d6.png?generation=1711656493536641&alt=media",

  apple:
    "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/bcbefe17508b0d515fe1ae71dde28795.png?generation=1711656642662979&alt=media",

  windows:
    "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/cac16ceb2782ee48999f84eecfe61ccb.png?generation=1711656722989961&alt=media",

  edge: "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/135df5fbdf41723604d4a0c84cc749a6.png?generation=1711656299898729&alt=media",
};
export default function Live() {
  const {
    user,
    gamesData,
    setGamesData,

    organization,
  } = useContext(SearchContext);

  const [loading, setLoadeing] = useState(true);

  const [callApi, setCallApi] = useState(1);

  useEffect(() => {
    getlist();
  }, [callApi]);

  const getlist = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=${gamesData.pageNoRe}&limit=12&live=pending&user_id=${user?.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          let all = [];
          if (gamesData.pageNoRe && gamesData.pageNoRe > 1) {
            all = gamesData.rewatchData.concat(data.feedList);
          } else {
            all = [...data.feedList];
          }
          all = all?.map((obj) => ({ ...obj, key: obj._id }));

          setGamesData({
            ...gamesData,
            rewatchData: all,

            totalPagesRe: data.totalPages,
          });
          if (gamesData.pageNoRe === 1) {
            setTimeout(() => {
              setCallApi(callApi + 1);
            }, 5000);
          }
        }

        setLoadeing(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");

          const all = gamesData.oldData2.filter((p) => p._id !== row._id);

          setGamesData({
            ...gamesData,
            rewatchData: all,

            totalPagesRe: data.totalPages,
          });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const emptyAlert = () => {
    return (
      <div class="notifications-container">
        <div class="dad-joke">
          <div class="flex">
            <div class="flex-shrink-0">
              <FaInfoCircle class="dad-joke-svg" />
            </div>
            <div class="dad-joke-prompt-wrap">
              <p class="dad-joke-prompt-heading">Dad Jokes Alert!</p>
              <div class="dad-joke-prompt">
                <p>
                  Why don’t scientists trust atoms? Because they make up
                  everything.
                </p>
              </div>
              <div class="dad-joke-button-container flex-end">
                <button
                  onClick={() => {
                    setGamesData({
                      ...gamesData,
                      gameCard: "browser",
                      showPlay: {
                        step: 1,
                        session: "single_session",

                        access: "private",
                        demographic_country: "united_states",
                      },
                      tabType: "initialize",
                    });
                  }}
                  class="dad-joke-button-main"
                  type="button"
                >
                  Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div style={{ width: "100%" }} className="active-goals-tc21">
        {/* <h5 style={{ textAlign: "center" }}>Live Machines</h5> */}

        <div className="assets-bx-h1">
          {gamesData.rewatchData === undefined ? (
            <div className="box-loading">
              <FullLoader />
            </div>
          ) : null}

          {gamesData.rewatchData && gamesData.rewatchData.length === 0 ? (
            <div className="center-alert-c"> {emptyAlert()}</div>
          ) : null}
          <Row>
            {gamesData.rewatchData &&
              gamesData.rewatchData.map((m, mi) => {
                return (
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    key={mi}
                    //   className="display-goal-st-s"
                    style={{ padding: "15px" }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${
                          m.thumbnail
                            ? m.thumbnail
                            : "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/a9331ac8e94adeeea5617da8ce2bd099.png?generation=1712749470412327&alt=media"
                        })`,
                      }}
                      class="c3-card"
                    >
                      <div class="c3-svg show-hover ">
                        <Popconfirm
                          title="Do You Want to Delete?"
                          onConfirm={() => deleteTask(m)}
                          // onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                          placement="top"
                          
                        >
                          <MdClose />
                        </Popconfirm>
                      </div>
                      <div className="public-top-c">
                        <b>{m.title}</b>
                      </div>

                      <div class="c3-logo">
                        <b>
                          <img
                            style={{ width: "30px" }}
                            src={surfaces[m.channelUuid]}
                            alt=""
                          />
                        </b>
                      </div>
                      <div class="c3-title">
                        <a href={`https://u.computer/${m._id}`} target="blank">
                          <div className="right-arrow-public">
                            <FaPlay />
                          </div>
                        </a>
                        <br />
                      </div>

                      <div class="c3-icons-container">
                        <div className="c323-icons-container">
                          {m.status === "pending" ||
                          m.status === "processing" ? (
                            <Spin style={{marginRight:"10px"}} indicator={antIcon} />
                          ) : null}
                          {m.status === "live" ? (
                            <a
                              href={`https://u.computer/${m._id}`}
                              target="blank"
                            >
                              <Tag color="rgb(205, 1, 1)">Live</Tag>
                            </a>
                          ) : null}
                          {m.status === "pause" ? (
                            <FaPauseCircle className=" main-yello-c" />
                          ) : null}

                          <AdjustStatus row={m} />

                          <PiGearSixBold
                            onClick={() => {
                              setGamesData({
                                ...gamesData,
                                editForm: m,
                                tabType: "edit",
                              });
                            }}
                            class="c3-svg "
                          />
                        </div>
                        <i> {moment(m.date).format("LL")}</i>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>

          {gamesData.totalPagesRe &&
          gamesData.totalPagesRe > gamesData.pageNoRe ? (
            <div className="load-more-btn">
              {loading ? (
                <Spin indicator={antIcon} />
              ) : (
                <Tag
                  onClick={() => {
                    setGamesData({
                      ...gamesData,
                      pageNoRe: gamesData.pageNoRe + 1,
                    });
                    setLoadeing(true);
                    setCallApi(callApi + 1);
                  }}
                  className={`editor-tag-tab  active-tg-wdt`}
                >
                  Load More <FaArrowDown />
                </Tag>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
