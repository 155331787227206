import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import FullLoader from "../../components/FullLoader";
import { Link } from "react-router-dom";
import logo from "../../images/Telemit.png";
import { Col, Input, Row, Spin, Tooltip } from "antd";
import { RiSendPlane2Fill } from "react-icons/ri";
import { LoadingOutlined } from "@ant-design/icons";
import GreenTick from "../../components/GreenTick";
import { MdError } from "react-icons/md";
export default function WatchVideo() {
  const [feedList, setfeedList] = useState();
  const [instructions, setInstructions] = useState();
  const [loading, setLoading] = useState();
  const [value, setValue] = useState("");

  useEffect(() => {
    getrolePlay(window.location.pathname.slice(11));
  }, []);

  const getrolePlay = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setfeedList(data.feedData);
        }

        setLoading(false);
      });
  };

  useEffect(() => {
    getInstructions(window.location.pathname.slice(11));
  }, []);

  const getInstructions = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/instruction?stream_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setInstructions(data.instructionList);
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "#bedbff",
        marginLeft: "5px",
      }}
      spin
    />
  );

  const createTask = () => {
    const obj = {
      instruction: value,
      stream_id: window.location.pathname.slice(8),
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/instruction`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
        }
      });
  };
  return (
    <div>
      <Link className="watch-logo-c" to="/">
        <img className="logo-img-bc" src={logo} alt="" />
      </Link>
      <div className="container">
        <div className="watch-page-mainc">
          <Row>
            <Col style={{ padding: "10px" }} span={15}>
              <div
                style={{ height: "100%" }}
                className=" video-watch-c video-worker-c"
              >
                {feedList ? (
                  <div
                    style={{ height: "auto" }}
                    className="video-container video-container-round"
                  >
                    <h5 className="gradient-text-title">Telemission</h5>
                    <ReactPlayer
                      loop
                      url={feedList.file_url}
                      playing
                      play
                      muted
                      controls
                    />
                  </div>
                ) : (
                  <FullLoader />
                )}
              </div>
            </Col>
            <Col style={{ padding: "10px" }} span={9}>
              <div className="instructions-r-c">
                <h5 className="gradient-text-title">AI Instructions</h5>
                <div className="ins-height-sc">
                  {instructions &&
                    instructions.map((ins, i) => {
                      return (
                        <div key={i} className="ins-watch-c">
                          <p>{ins.instruction}</p>
                          <div className="flex-end">
                            <b>
                              {ins.status === "complete" ? (
                                <GreenTick width={"25px"} height={"25px"} />
                              ) : null}
                              {ins.status === "pending" ? (
                                <Spin indicator={antIcon} />
                              ) : null}
                              {ins.status === "error" ? (
                                <Tooltip title="Something Went Wrong">
                                  <MdError className="error-s-svg" />
                                </Tooltip>
                              ) : null}
                            </b>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="chat-bx-input-c">
                  <Input.TextArea
                    placeholder="Type..."
                    autoSize={{ minRows: 1, maxRows: 3 }}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    class="input-field-pf"
                  />

                  {loading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <div
                      className={`icon-left-ed   active-i-l-ed`}
                      style={{ width: "34px" }}
                      onClick={() => {
                        if (!loading) {
                          createTask();
                          setLoading(true);
                        }
                      }}
                    >
                      <RiSendPlane2Fill />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
