import { Button, Col, Row, Spin, Tag, message } from "antd";
import React, { useEffect, useState, useRef, useContext } from "react";
import { BsCardImage } from "react-icons/bs";
import {
  FaArrowDown,
  FaArrowRight,
  FaInfoCircle,
  FaPlay,
} from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import SearchContext from "../../../Context/SearchContext";
import Paragraph from "antd/es/typography/Paragraph";
import TagsGroup from "../../../components/TagsGroup";
import ReactPlayer from "react-player";
export default function WatchStatus({ row }) {
  const {
    user,
    gamesData,
    setGamesData,

    organization,
  } = useContext(SearchContext);
  const [loadingId, setloadingId] = useState(false);
  const [activeData, setActiveData] = useState();
  const [activeId, setactiveId] = useState();
  const [callApi, setCallApi] = useState(1);
  const [loading, setLoadeing] = useState(true);
  const playerRef = useRef(null);

  useEffect(() => {
    if (gamesData.dataTab === undefined || callApi > 1) {
      getVector();
    } else {
      setLoadeing(false);
    }
  }, [callApi]);

  const getVector = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `https://embed.axv.ai/api/vector?page=${
        gamesData.pageNoD
      }&limit=40&sort_by=${-1}&user_id=${user?.user_id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch vectors");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.isSuccess) {
          let all = [];
          if (gamesData.pageNoD && gamesData.pageNoD > 1) {
            all = gamesData.dataTab.concat(data.vectorList);
          } else {
            all = [...data.vectorList];
          }
          all = all?.map((obj) => ({ ...obj, key: obj._id }));

          setGamesData({
            ...gamesData,
            dataTab: all,
            totalPagesD: data.totalPages,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching vectors:", error.message);
      });
  };

  const DynamicTimeDisplay = (d) => {
    let hours = Math.floor(d / 3600);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = Math.floor((d % 3600) / 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let secondsRemaining = Math.floor(d % 60);
    let seconds =
      secondsRemaining < 10 ? "0" + secondsRemaining : secondsRemaining;

    return (
      <div className="flex-end-bvh">
        {hours}:{minutes}:{seconds}
      </div>
    );
  };
  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/vector/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let all = gamesData.dataTab.map((m) =>
            m._id === id ? response.vectorData : m
          );
          setGamesData({
            ...gamesData,
            dataTab: all,
          });
        } else {
          message.error("somehing went wrong");
        }
      });
  };
  const getrolePlay = (stream_id, id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${stream_id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setloadingId(false);
        if (data && data.isSuccess) {
          setActiveData(data.feedData);
          setactiveId(id);
        }
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  const adjustVideoTime = (seconds) => {
    // Ensure React Player reference is available
    if (playerRef.current) {
      // Seek to the specified time in seconds
      playerRef.current.seekTo(seconds, "seconds");
    }
  };
  const emptyAlert = () => {
    return (
      <div class="notifications-container">
        <div class="dad-joke">
          <div class="flex">
            <div class="flex-shrink-0">
              <FaInfoCircle class="dad-joke-svg" />
            </div>
            <div class="dad-joke-prompt-wrap">
              <p class="dad-joke-prompt-heading">Dad Jokes Alert!</p>
              <div class="dad-joke-prompt">
                <p>
                  Why don’t scientists trust atoms? Because they make up
                  everything.
                </p>
              </div>
              <div class="dad-joke-button-container flex-end">
                <button class="dad-joke-button-main" type="button">
                  undefined
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <h4 className="gradient-text-title" style={{ textAlign: "center" }}>Data</h4>
      <div className="container">
        {gamesData.dataTab && gamesData.dataTab.length === 0 ? (
          <div className="center-alert-c"> {emptyAlert()}</div>
        ) : null}
        <Row>
          <Col
            lg={{ span: 3 }}
            md={{ span: 1 }}
            sm={{ span: 0 }}
            xs={{ span: 0 }}
          ></Col>
          <Col
            lg={{ span: 18 }}
            md={{ span: 22 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            {gamesData.dataTab &&
              gamesData.dataTab.map((v, vi) => (
                <div
                  id={`id${v.timestamp}`}
                  key={vi}
                  className={`vector-r-card ${
                    activeData?._id === v.stream_id && v._id === activeId
                      ? " active-v-c2"
                      : ""
                  }`}
                >
                  {activeData?._id === v.stream_id && v._id === activeId ? (
                    <div className="video-container radius-video">
                      <ReactPlayer
                        ref={playerRef}
                        url={activeData.file_url}
                        className=""
                        controls
                        playing
                        play
                        onStart={() => {
                          if (v.timestamp) {
                            adjustVideoTime(v.timestamp);
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <Row>
                      <Col
                        lg={{ span: 7 }}
                        md={{ span: 7 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div style={{ position: "relative" }}>
                            <img src={v.thumbnail} alt="" />
                            <div class="c3-title">
                              <div
                                onClick={() => {
                                  setloadingId(v._id);
                                  getrolePlay(v.stream_id, v._id);
                                }}
                                className="right-arrow-public"
                              >
                                {loadingId === v._id ? (
                                  <Spin indicator={antIcon} />
                                ) : (
                                  <FaPlay />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={{ span: 17 }}
                        md={{ span: 17 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}
                      >
                        <div className="vc-c-ric">
                          <div>
                            <h6>Detail</h6>
                            <p>{v.content}</p>
                            <div>
                              <h6>Topics</h6>
                              <TagsGroup
                                tags={v.tags ? v.tags : []}
                                color="#009450"
                                onTagChange={(val) => {
                                  updateValue({ tags: val }, v._id);
                                }}
                              />
                            </div>
                          </div>
                          {v.timestamp ? (
                            <div className="flex-end">
                              {DynamicTimeDisplay(v.timestamp)}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              ))}
          </Col>
        </Row>
      </div>

      {gamesData.totalPagesD && gamesData.totalPagesD > gamesData.pageNoD ? (
        <div className="load-more-btn">
          {loading ? (
            <Spin indicator={antIcon} />
          ) : (
            <Tag
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  pageNoD: gamesData.pageNoD + 1,
                });
                setLoadeing(true);
                setCallApi(callApi + 1);
              }}
              className={`editor-tag-tab  active-tg-wdt`}
            >
              Load More <FaArrowDown />
            </Tag>
          )}
        </div>
      ) : null}
    </div>
  );
}
