import React, { useContext, useState } from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import SearchContext from "../../../Context/SearchContext";
import {
  Button,
  Col,
  Drawer,
  Input,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  message,
} from "antd";
import { PiGearSixBold } from "react-icons/pi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ShowIcon from "./components/ShowIcon";
import { FaArrowDown } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function Applications() {
  const navigate = useNavigate();

  const { user, generated, setGenerated } = useContext(SearchContext);
  const [add, setAdd] = useState();
  const updateBehavior = (obj, id) => {
    delete obj._id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/behavior/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          let all = generated.created.map((m) =>
            m._id === id ? data.behaviorData : m
          );
          setGenerated({ ...generated, created: all });
        } else {
          message.error("something went wrong");
        }
      });
  };
  const deleteBehavior = (row) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/behavior/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = generated.created.filter((p) => p._id !== row._id);
          setGenerated({ ...generated, created: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div className="active-goals-tc21 application-div">
      <Row>
        {generated.created &&
          generated.created.map((m, mi) => {
            return (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 6 }}
                md={{ span: 8 }}
                key={mi}
                style={{ padding: "10px", position: "relative" }}
              >
                <div
                  className={`function-card-c`}
                  onClick={() => {
                    setGenerated({
                      ...generated,
                      app: m,
                      pages: undefined,
                    });
                    navigate(`/application/${m.app_id ? m.app_id : m._id}`);
                  }}
                >
                  <ShowIcon icon={m.app_icon} />
                  <Paragraph
                    ellipsis={{
                      rows: 1,
                      // expandable: true,
                      symbol: "see more",
                    }}
                  >
                    <b> {m.app_name}</b>
                  </Paragraph>
                </div>
                <PiGearSixBold
                  className="grear-icon-s"
                  onClick={() => setAdd(m)}
                />
              </Col>
            );
          })}
      </Row>
      {generated.totalPages && generated.totalPages > generated.pageNo ? (
        <div className="load-more-btn">
          {generated.loading ? (
            <Spin indicator={antIcon} />
          ) : (
            <Tag
              onClick={() => {
                setGenerated({
                  ...generated,
                  pageNo: generated.pageNo + 1,
                  callApi: generated.callApi ? generated.callApi + 1 : 1,
                  loading: true,
                });
              }}
            >
              Load More <FaArrowDown />
            </Tag>
          )}
        </div>
      ) : null}
      <Drawer
        open={add !== undefined}
        onClose={() => {
          setAdd();
        }}
        className=" black-drawer "
      >
        <h5 className="create-btn-h">
          {" "}
          {add && add._id ? "Update" : "Add"} Behavior
        </h5>
        {add ? (
          <div className=" behavior-table-add">
            <table>
              <tbody>
                <tr className="input-bx-pas">
                  <td>
                    <h6>Name:</h6>
                  </td>
                  <td>
                    <Input
                      size="large"
                      placeholder="Name"
                      onChange={(e) => {
                        setAdd({ ...add, name: e.target.value });
                      }}
                      value={add.name}
                      className={`light-input`}
                    />
                  </td>
                </tr>
                <tr className="input-bx-pas">
                  <td>
                    <h6>Moderation Shield:</h6>
                  </td>
                  <td>
                    <Switch
                      onChange={(e) => {
                        setAdd({ ...add, moderation_shield: e });
                      }}
                      checked={add.moderation_shield}
                    ></Switch>
                  </td>
                </tr>
                <tr className="input-bx-pas">
                  <td>
                    <h6>Copyright Shield:</h6>
                  </td>
                  <td>
                    <Switch
                      onChange={(e) => {
                        setAdd({ ...add, copyright_shield: e });
                      }}
                      checked={add.copyright_shield}
                    ></Switch>
                  </td>
                </tr>
                <tr className="input-bx-pas">
                  <td>
                    <h6>Security Shield:</h6>
                  </td>
                  <td>
                    <Switch
                      onChange={(e) => {
                        setAdd({ ...add, security_shield: e });
                      }}
                      checked={add.security_shield}
                    ></Switch>
                  </td>
                </tr>
                <tr className="input-bx-pas">
                  <td>
                    <h6>Retention Policy:</h6>
                  </td>
                  <td>
                    <Select
                      value={add.retention_policy}
                      aria-label="Default select example"
                      onChange={(e) => {
                        setAdd({ ...add, retention_policy: e });
                      }}
                      className="light-input"
                      style={{ width: "80px" }}
                    >
                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                      <Option value={3}>3</Option>
                      <Option value={4}>4</Option>
                    </Select>
                  </td>
                </tr>
                {/* <tr className="input-bx-pas">
                  <td className="heading-h-table">
                    <h6>Headshot:</h6>
                  </td>
                  <td>
                    <div className="preview-input-c">
                      <Input.TextArea
                        className="light-input"
                        placeholder="Type Anything"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                        onChange={(e) => {
                          setPreviewText(e.target.value);
                        }}
                        checked={previewText}
                      />
                      {previewText && previewText.length ? (
                        loadingP ? (
                          <Spin />
                        ) : (
                          <Button
                            size="small"
                            className={`editor-tag-tab active-tg-wdt `}
                            onClick={() => {
                              setLoadingP(true);
                              PreviewImage(previewText);
                            }}
                          >
                            Create
                          </Button>
                        )
                      ) : null}
                    </div>
                    {add.headshot ? (
                      <div>
                        <img
                          className="preview-img-c-m"
                          src={add.headshot}
                          alt=""
                        />
                      </div>
                    ) : null}
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="flex-end">
          <Button
            onClick={() => {
              deleteBehavior(add);
              // setPreviewText("");
              setAdd();
            }}
            style={{ marginRight: "10px" }}
            className={`editor-tag-tab active-tg-wdt `}
          >
            Delete
          </Button>
          {add && add.name ? (
            <Button
              onClick={() => {
                updateBehavior(add, add._id);
                setAdd();
                // setPreviewText("");
              }}
              className={`editor-tag-tab active-tg-wdt `}
            >
              Update
            </Button>
          ) : null}
        </div>
      </Drawer>
    </div>
  );
}
