import { Input, Layout, Tooltip } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../../../Context/SearchContext";
import AddBlock from "./AddBlock";
import Elements from "../../../../images/Elements.png";

const { Header, Sider, Content } = Layout;
export default function ElementsHeader({ style }) {
  const { images, setImages, user, tasks, setTasks } =
    useContext(SearchContext);

  return (
    <div className="header-cards-btn">
      <div className="help-text-con">
        <img className="top-active-img" src={Elements} alt="" />

        <a
          style={{ textDecoration: "none", marginRight: "10px" }}
          href="https://guide.dendric.com/engrams/elements"
          target="blank"
        >
          <Tooltip title="Help">
            <div
              style={{ backgroundColor: "#fde281" }}
              className="hover-card-b"
            >
              Guide
            </div>
          </Tooltip>
        </a>
        <AddBlock />
      </div>
      <div>
        <Input
          value={tasks.bloackSearch}
          allowClear
          onChange={(e) => {
            setImages({
              ...images,
              blocks: undefined,
              totalPBlock: undefined,
              callBlocks: images.callBlocks ? images.callBlocks + 1 : 1,
              pageNoB: 1,
            });
            setTasks({ ...tasks, bloackSearch: e.target.value });
          }}
          className="light-input"
          size="large"
          placeholder="Search"
        />
      </div>

      <div className="filter-box-r">
        <div
          className="hover-card-b"
          style={{
            backgroundColor:
              images.blocksUser === user?.user_id ||
              images.blocksUser === undefined
                ? "#c1ff98"
                : "#fe9aa3",
            marginRight: "10px",
          }}
          onClick={() => {
            setImages({
              ...images,
              blocks: undefined,
              totalPBlock: undefined,
              callBlocks: images.callBlocks ? images.callBlocks + 1 : 1,
              blocksUser: user && user.user_id,
              pageNoB: 1,
            });
          }}
        >
          My Elements
        </div>
        <div
          style={{
            backgroundColor:
              images.blocksUser === "prebuilt" ? "#c1ff98" : "#fe9aa3",
          }}
          className="hover-card-b"
          onClick={() => {
            setImages({
              ...images,
              blocks: undefined,
              totalPBlock: undefined,
              callBlocks: images.callBlocks ? images.callBlocks + 1 : 1,
              blocksUser: "prebuilt",
              pageNoB: 1,
            });
          }}
        >
          Made By Community
        </div>
      </div>
    </div>
  );
}
