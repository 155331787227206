import {
  Badge,
  Button,
  Input,
  Layout,
  Modal,
  Select,
  Spin,
  Tooltip,
} from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import applications from "../../../../images/applications.png";
import { LoadingOutlined } from "@ant-design/icons";
import { RiCheckboxMultipleBlankFill } from "react-icons/ri";
import { FaParachuteBox } from "react-icons/fa";
import UploadAttachments from "./UploadAttachments";
import { MdClose, MdOutlineAttachFile } from "react-icons/md";
const { Option } = Select;
export default function ApplicationHeader({ style }) {
  const { images, setImages, user, setGenerated, generated } =
    useContext(SearchContext);
  const [loading, setLoading] = useState(false);
  const [app_name, setAppName] = useState("");
  const [value, setValue] = useState("");
  const [fun, setFun] = useState("");
  const [sty, setSty] = useState("");

  const addPage = () => {
    const obj = {
      user_id: user && user.user_id,
      query: value,
      function: fun,
      style: sty,
      app_name,
      website: "default",
      attachments: generated.attachments,
      page_count: generated.page_count ? generated.page_count : 1,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/v1/app/generate`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        if (response && response) {
          let all = generated.created ? generated.created : [];
          all.unshift({ ...response, query: value });
          setGenerated({ ...generated, created: all });
          setValue("");
        } else {
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        // fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div>
      <div className="header-cards-btn">
        <div className="help-text-con">
          <img className="top-active-img" src={applications} alt="" />

          <a
            style={{ textDecoration: "none", marginRight: "10px" }}
            href="https://guide.dendric.com/engrams/elements"
            target="blank"
          >
            <Tooltip title="Help">
              <div
                style={{ backgroundColor: "#fde281" }}
                className="hover-card-b"
              >
                Guide
              </div>
            </Tooltip>
          </a>
          <Tooltip title="Generate">
            <div
              onClick={() => {
                setGenerated({
                  ...generated,
                  showGenerate: true,
                });
              }}
              style={{
                backgroundColor: generated.showGenerate ? "#c1ff98" : "#fe9aa3",
              }}
              className="hover-card-b"
            >
              Generate
            </div>
          </Tooltip>
        </div>

        <div className="filter-box-r">
          <div
            className="hover-card-b"
            style={{
              backgroundColor:
                generated.generateUser === user?.user_id ||
                generated.generateUser === undefined
                  ? "#c1ff98"
                  : "#fe9aa3",
              marginRight: "10px",
            }}
            onClick={() => {
              setGenerated({
                ...generated,
                created: undefined,
                totalPages: undefined,
                pageNo: 1,
                callApi: generated.callApi ? generated.callApi + 1 : 1,
                loading: false,
                generateUser: user?.user_id,
              });
            }}
          >
            My App
          </div>
          <div
            style={{
              backgroundColor:
                generated.generateUser === "prebuilt" ? "#c1ff98" : "#fe9aa3",
            }}
            className="hover-card-b"
            onClick={() => {
              setGenerated({
                ...generated,
                created: undefined,
                totalPages: undefined,
                pageNo: 1,
                callApi: generated.callApi ? generated.callApi + 1 : 1,
                loading: false,
                generateUser: "prebuilt",
              });
            }}
          >
            Made By Community
          </div>
        </div>
      </div>
      {generated.showGenerate ? (
        <div>
          <div className="home-input-c-c">
            <div className="parameters-d-cli">
              <b>Parameters</b>
              <div className="create-input-m">
                <Input.TextArea
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  className="light-input"
                  size="large"
                  placeholder="Describe your app"
                  style={{
                    width:
                      value.length < 27
                        ? 300
                        : value.length > 35
                        ? 420
                        : value.length > 27
                        ? 350
                        : 300,
                  }}
                />
              </div>
              <div className="close-icon">
                <MdClose
                  onClick={() => {
                    setGenerated({
                      ...generated,
                      showGenerate: undefined,
                    });
                  }}
                />
              </div>
            </div>
            <table style={{ margin: "0px 0px" }} className="block-add-tbk">
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}>Pages :</b>
                  </td>
                  <td>
                    <Select
                      value={generated.page_count ? generated.page_count : 1}
                      aria-label="Page Count"
                      onChange={(v) =>
                        setGenerated({ ...generated, page_count: v })
                      }
                      className="light-input"
                      style={{ width: "60px", marginLeft: "10px" }}
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((p, pi) => {
                        return (
                          <Option key={pi} value={p}>
                            {p}
                          </Option>
                        );
                      })}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}>Attachments :</b>
                  </td>
                  <td>
                    <div className="home-filters-con">
                      <b onClick={() => console.log(generated)}>
                        {generated.attachments ? (
                          <Badge
                            count={
                              generated.attachments
                                ? generated.attachments.length
                                : 0
                            }
                          >
                            <MdOutlineAttachFile size={32} />
                          </Badge>
                        ) : null}
                        <UploadAttachments />
                      </b>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}>App Name :</b>
                  </td>
                  <td>
                    <Input
                      value={app_name}
                      onChange={(e) => setAppName(e.target.value)}
                      className="light-input"
                      size="large"
                      placeholder="App Name"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}>Style :</b>
                  </td>
                  <td>
                    <Input
                      value={sty}
                      onChange={(e) => setSty(e.target.value)}
                      className="light-input"
                      size="large"
                      placeholder="Style"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}>Function :</b>
                  </td>
                  <td>
                    <Input
                      value={fun}
                      onChange={(e) => setFun(e.target.value)}
                      className="light-input"
                      size="large"
                      placeholder="Function"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="flex-end">
              {value && value.length ? (
                loading ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <Button
                    onClick={() => {
                      setLoading(true);
                      addPage();
                    }}
                    style={{ borderRadius: "3px" }}
                  >
                    Generate
                  </Button>
                )
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
