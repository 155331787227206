import "./App.css";
import SearchState from "./Context/SearchState";
import Pages from "./pages/Pages";
import { ClerkProvider } from "@clerk/clerk-react";
import MyLayout from "./pages/Layout";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

// if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
//   throw new Error("Missing Publishable Key");
// }
// const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
function App() {
  // console.log(process.env.REACT_APP_CLERK_PUBLISHABLE_KEY)
  return (
    <ClerkProvider
      publishableKey={
        "pk_test_a2Vlbi13ZXJld29sZi04Ni5jbGVyay5hY2NvdW50cy5kZXYk"
      }
    >
      <SearchState>
        <Router>
          <Pages />
        </Router>
      </SearchState>
    </ClerkProvider>
  );
}

export default App;
