import {
  Button,
  Col,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import { FaArrowLeft } from "react-icons/fa";
const { Option } = Select;
export default function CreateRole({ rowData, dAdd, setdAdd }) {
  const { setImages, images, user, createRole, setCreateRole } =
    useContext(SearchContext);

  const SaveRole = (row) => {
    row.user_id = user.user_id;
    row.model_status = "ingest";
    // row.router_id = behavior.selected ? behavior.selected._id : "auto";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`https://embed.axv.ai/api/feed`, requestOptions)
      .then((response) => {
        if (response.status === 400) {
          //   setShowPrice(true);
          return response.json(); // You may also want to parse the error response
        }
        return response.json();
      })
      .then((data) => {
        if (data.isSuccess) {
          const all = createRole.data ? [...createRole.data] : [];
          all.unshift(data.data);
          setCreateRole({ ...createRole, data: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const updateValue = (obj, id) => {
    delete obj._id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isPatched) {
          let all = createRole.data.map((m) =>
            m._id === response.patchedData._id ? response.patchedData : m
          );
          setCreateRole({ ...createRole, data: all });
        } else {
          message.error("somehing went wrong");
        }
      });
  };

  return (
    <div>
      {dAdd ? (
        <div
          style={{ marginTop: "20px" }}
          className="bx-form-brand no-radius-input"
        >
          {dAdd.domain ? null : (
            <div style={{ width: "100%" }}>
              <h5 style={{ marginBottom: "10px" }}>Select Domain</h5>
              <div className="domain-cards-bx">
                <div
                  className="hover-card-b"
                  style={{
                    backgroundColor:
                      dAdd.domain === "gaming" ? "#c1ff98" : "transparent",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    setdAdd({
                      ...dAdd,
                      domain: "gaming",
                    });
                  }}
                >
                  Gaming
                </div>
                <div
                  className="hover-card-b"
                  style={{
                    backgroundColor:
                      dAdd.domain === "software" ? "#c1ff98" : "transparent",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    setdAdd({
                      ...dAdd,
                      domain: "software",
                    });
                  }}
                >
                  Software
                </div>
                <div
                  className="hover-card-b"
                  style={{
                    backgroundColor:
                      dAdd.domain === "ecommerce" ? "#c1ff98" : "transparent",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    setdAdd({
                      ...dAdd,
                      domain: "ecommerce",
                    });
                  }}
                >
                  eCommerce
                </div>
              </div>
            </div>
          )}
          {dAdd.domain ? (
            <div>
              <FaArrowLeft
                onClick={() =>
                  setdAdd({
                    ...dAdd,
                    domain: undefined,
                  })
                }
                size={18}
                className="back-icn hover"
              />
              <div>
                {dAdd._id ? (
                  <h3>Edit {dAdd.domain}</h3>
                ) : (
                  <h3>Add {dAdd.domain}</h3>
                )}
              </div>
              <table style={{ marginTop: "10px" }} className="block-add-tbk">
                <tbody>
                  <tr>
                    <td>
                      <b style={{ marginRight: "10px" }}> Name:</b>
                    </td>
                    <td>
                      <Input
                        className="bx-fld-10t grey-input"
                        placeholder="Name"
                        value={dAdd.name}
                        onChange={(e) =>
                          setdAdd({
                            ...dAdd,
                            name: e.target.value,
                          })
                        }
                        style={{ width: 300 }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b style={{ marginRight: "10px" }}> Description:</b>
                    </td>
                    <td>
                      <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        className="bx-fld-10t grey-input"
                        placeholder="Description"
                        value={dAdd.description}
                        onChange={(e) =>
                          setdAdd({
                            ...dAdd,
                            description: e.target.value,
                          })
                        }
                        style={{ width: 300 }}
                      />
                    </td>
                  </tr>
                  {/* {dAdd.domain !== "gaming" && dAdd.domain !== "software" ? (
                      <tr>
                        <td>
                          <b style={{ marginRight: "10px" }}> Duration:</b>
                        </td>
                        <td>
                          <Select
                            className="bx-fld-10t grey-input"
                            placeholder="Duration"
                            value={dAdd.duration}
                            onChange={(e) =>
                              setdAdd({
                                ...dAdd,
                                duration: e,
                              })
                            }
                            style={{ width: 300 }}
                          >
                            <Option value={8}>8 Hours</Option>
                            <Option value={16}>16 Hours</Option>
                            <Option value={24}>24 Hours</Option>
                            <Option value={32}>32 Hours</Option>
                            <Option value={48}>48 Hours</Option>
                          </Select>
                        </td>
                      </tr>
                    ) : null} */}
                  {/* {dAdd.domain !== "gaming" && dAdd.domain !== "software" ? (
                      <tr>
                        <td>
                          <b style={{ marginRight: "10px" }}> Task:</b>
                        </td>
                        <td>
                          <Select
                            className="bx-fld-10t grey-input"
                            placeholder="Duration"
                            value={dAdd.task}
                            onChange={(e) =>
                              setdAdd({
                                ...dAdd,
                                task: e,
                              })
                            }
                            style={{ width: 300 }}
                          >
                            <Option value="ux">UX</Option>
                            <Option value="audit">Audit</Option>
                            <Option value="bug_review">Bug Review</Option>
                            <Option value="ai_quality_control)">
                              AI Quality Control
                            </Option>
                          </Select>
                        </td>
                      </tr>
                    ) : null} */}
                  <tr>
                    <td>
                      <b style={{ marginRight: "10px" }}> Status:</b>
                    </td>
                    <td>
                      <Select
                        className="bx-fld-10t grey-input"
                        placeholder="Status"
                        value={dAdd.status}
                        onChange={(e) =>
                          setdAdd({
                            ...dAdd,
                            status: e,
                          })
                        }
                        style={{ width: 300 }}
                      >
                        <Option value={"active"}>Active</Option>
                        <Option value={"pending"}>Pending</Option>
                        <Option value={"error"}>Error</Option>
                        <Option value={"inactive"}>Inactive</Option>
                      </Select>
                    </td>
                  </tr>
                  {/* {dAdd.domain !== "gaming" && dAdd.domain !== "software" ? (
                      <tr>
                        <td>
                          <b style={{ marginRight: "10px" }}> Notification:</b>
                        </td>
                        <td>
                          <Select
                            className="bx-fld-10t grey-input"
                            placeholder="Notification"
                            value={dAdd.notification}
                            onChange={(e) =>
                              setdAdd({
                                ...dAdd,
                                notification: e,
                              })
                            }
                            style={{ width: 300 }}
                          >
                            <Option value={"email"}>Email</Option>
                            <Option value={"console"}>Console</Option>
                          </Select>
                        </td>
                      </tr>
                    ) : null} */}

                  {dAdd.domain === "gaming" ? (
                    <tr>
                      <td>
                        <b style={{ marginRight: "10px" }}>Sub Domain:</b>
                      </td>
                      <td>
                        <Select
                          className="bx-fld-10t grey-input"
                          placeholder="Sub Domain"
                          value={dAdd.subdomain}
                          onChange={(e) =>
                            setdAdd({
                              ...dAdd,
                              subdomain: e,
                            })
                          }
                          style={{ width: 300 }}
                        >
                          <Option value={"steam"}>Steam</Option>
                          <Option value={"epic_store"}>Epic Store</Option>
                          <Option value={"android"}>android</Option>
                        </Select>
                      </td>
                    </tr>
                  ) : null}
                  {dAdd.domain === "gaming" ? (
                    <tr>
                      <td>
                        <b style={{ marginRight: "10px" }}>Game Link:</b>
                      </td>
                      <td>
                        <Input
                          className="bx-fld-10t grey-input"
                          placeholder="Game Link"
                          value={dAdd.game_link}
                          onChange={(e) =>
                            setdAdd({
                              ...dAdd,
                              game_link: e.target.value,
                            })
                          }
                          style={{ width: 300 }}
                        />
                      </td>
                    </tr>
                  ) : null}

                  {dAdd.domain !== "gaming" ? (
                    <tr>
                      <td>
                        <b style={{ marginRight: "10px" }}> Website:</b>
                      </td>
                      <td>
                        <Input
                          className="bx-fld-10t grey-input"
                          placeholder="Website"
                          value={dAdd.website}
                          onChange={(e) =>
                            setdAdd({
                              ...dAdd,
                              website: e.target.value,
                            })
                          }
                          style={{ width: 300 }}
                        />
                      </td>
                    </tr>
                  ) : null}
                  {dAdd.domain !== "gaming" ? (
                    <tr>
                      <td>
                        <b style={{ marginRight: "10px" }}> Login Required:</b>
                      </td>
                      <td>
                        <Switch
                          checked={dAdd.login_required}
                          onChange={(e) =>
                            setdAdd({
                              ...dAdd,
                              login_required: e,
                            })
                          }
                        />
                      </td>
                    </tr>
                  ) : null}
                  {dAdd.login_required && dAdd.domain !== "gaming" ? (
                    <tr>
                      <td>
                        <b style={{ marginRight: "10px" }}> Email:</b>
                      </td>
                      <td>
                        <Input
                          className="bx-fld-10t grey-input"
                          placeholder="Email"
                          value={dAdd.email}
                          onChange={(e) =>
                            setdAdd({
                              ...dAdd,
                              email: e.target.value,
                            })
                          }
                          style={{ width: 300 }}
                        />
                      </td>
                    </tr>
                  ) : null}
                  {dAdd.login_required || dAdd.domain === "gaming" ? (
                    <tr>
                      <td>
                        <b style={{ marginRight: "10px" }}> Password:</b>
                      </td>
                      <td>
                        <Input
                          className="bx-fld-10t grey-input"
                          placeholder="Password"
                          value={dAdd.password}
                          onChange={(e) =>
                            setdAdd({
                              ...dAdd,
                              password: e.target.value,
                            })
                          }
                          style={{ width: 300 }}
                        />
                      </td>
                    </tr>
                  ) : null}

                  <tr>
                    <td>
                      <b style={{ marginRight: "10px" }}>Runtime:</b>
                    </td>
                    <td>
                      <InputNumber
                        value={dAdd.runtime ? dAdd.runtime : 1}
                        onChange={(e) =>
                          setdAdd({
                            ...dAdd,
                            runtime: e,
                          })
                        }
                        min={1}
                        max={60}
                        style={{ width: 100 }}
                      />{" "}
                      <b className="runtime-amount">
                        ${dAdd.runtime ? (dAdd.runtime * 0.3).toFixed(2) : 0.3}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="flex-end">
                {dAdd._id ? (
                  <Button
                    onClick={() => {
                      updateValue(dAdd, dAdd._id);
                      setdAdd();
                    }}
                    style={{ borderRadius: "3px" }}
                    className="build-button-c"
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      SaveRole(dAdd);
                      setdAdd();
                    }}
                    style={{ borderRadius: "3px" }}
                    className="build-button-c"
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
