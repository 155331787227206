import { Col, Row, Select } from "antd";

import React, { useContext, useEffect, useState } from "react";

import { FaArrowDown, FaMinus } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";
import SearchContext from "../../Context/SearchContext";
import { BsChatDots } from "react-icons/bs";
import SimulationChat from "./components/SimulationChat";

import "./videolive.css";
import UxTab from "./UxTab";

const { Option } = Select;
export default function SimulationVideo({ row }) {
  const {
    user,
    gamesData,
    setGamesData,

    organization,
  } = useContext(SearchContext);
  // const [show, setShow] = useState("live");
  const [loading, setLoading] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [feedList, setfeedList] = useState();
  const [callApi, setCallApi] = useState(1);
  const [dChat, setDChat] = useState(true);
  const [tab, setTab] = useState("Chat");

  useEffect(() => {
    getrolePlay(window.location.pathname.slice(1));
  }, [pageNo, callApi]);

  const getrolePlay = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setfeedList(data.feedData);
          setTotalPages(data.totalPages);
          // if (pageNo === 1 && show === "live") {
          //   setTimeout(() => {
          //     setCallApi(callApi + 1);
          //   }, 15000);
          // }
        }

        setLoading(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  return (
    <div>

      <div className="sumilation-main-d">
        <div className="container">
          {feedList && feedList.length === 0 ? (
            <div
              className="no-heigh-div-c"
              style={{ maxWidth: "550px", margin: "auto" }}
            >
              <div className="card21">
                <div className="card21-img"></div>
                <div className="card21-info">
                  <p className="text21-body">Create a Block</p>
                  <p className="txt-card-21">
                    Leverage blocks to add context about your assets to your
                    creations. Use the upload button to create your first block.
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {feedList? (
            <Row>
              <Col style={{ padding: "10px" }} span={dChat ? 15 : 24}>
                <div className="chat-video-right">
                  <iframe
                    title="External Website"
                    src={feedList.stream_url}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </div>
              </Col>
              <Col style={{ padding: "10px" }} span={dChat ? 9 : 0}>
                <div className="chat-video-right">
                  <div className="chat-close-icn">
                    <FaMinus onClick={() => setDChat(false)} />
                  </div>
                  <div className="s3-tabs-c">
                    <button
                      onClick={() => {
                        setTab("Chat");
                      }}
                      className={`${
                        tab === "Chat" ? " active-live-c " : ""
                      }  c3button`}
                    >
                      <span>Chat</span>
                    </button>
                    <button
                      onClick={() => {
                        setTab("UX");
                      }}
                      className={`${
                        tab === "UX" ? " active-live-c " : ""
                      }  c3button`}
                    >
                      <span>UX</span>
                    </button>
                    <button
                      onClick={() => {
                        setTab("Logs");
                      }}
                      className={`${
                        tab === "Logs" ? " active-live-c " : ""
                      }  c3button`}
                    >
                      <span>Logs</span>
                    </button>
                
                
                  </div>
                  {tab === "Chat" ? (
                    <SimulationChat row={feedList} />
                  ) : null}
                  {tab === "UX" ? <UxTab row={feedList} /> : null}
                </div>
              </Col>
            </Row>
          ) : null}
        </div>
      </div>
      {dChat && tab === "Chat" ? null : (
        <div className="chat-open-icn">
          <BsChatDots onClick={() => setDChat(true)} />
        </div>
      )}
    </div>
  );
}
