import React, { useContext, useEffect, useState } from "react";

import {
  Link,
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Layout, Menu, Button, theme, Dropdown } from "antd";

import logo from "../images/Copy of ModelUI (256 x 256 px) (5).png";
import Functions from "../images/Functions.png";
import Elements from "../images/Elements.png";
import activity from "../images/activity.png";
import playground from "../images/playground.png";
import account from "../images/account.png";
import developers from "../images/developers.png";
import temp from "../images/2222628-200.png";
import applications from "../images/applications.png";
import dialogue from "../images/dialogue.png";
import roleplay from "../images/roleplay.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import SearchContext from "../Context/SearchContext";
import ElementsHeader from "./home/tabs/components/ElementsHeader";
import AccountsHeader from "./account/components/AccountsHeader";
import ApplicationHeader from "./home/tabs/components/ApplicationHeader";
import PlaydroundHeader from "./playground/components/PlaydroundHeader";
import FunctionsHeader from "./home/tabs/components/FunctionsHeader";
import GeneratedHeader from "./generated/components/GeneratedHeader";
import RoleplayHeader from "./roleplay/components/RoleplayHeader";
import SimulationHeader from "./simulation/components/SimulationHeader";

const { Header, Sider, Content } = Layout;
const MyLayout = ({ page }) => {
  const {
    user,
    setUser,
    setOrganization,
    setShowPrice,
    resetAll,
    organization,
  } = useContext(SearchContext);
  const locatoin = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  useEffect(() => {
    let elements = document.getElementsByClassName("ant-layout-content");
    if (elements && elements.length) {
      elements[0].scroll(0, 0);
    }
  }, [locatoin]);

  const items = [
    // {
    //   key: "0",
    //   label: <span onClick={() => setDisplay("account")}>Account</span>,
    // },
    // {
    //   key: "1",
    //   label: <span onClick={() => setShowPrice(true)}>Billing</span>,
    // },
    {
      key: "2",
      label: <span onClick={() => logout()}>Log Out</span>,
    },
  ];
  const logout = () => {
    window.localStorage.removeItem("user");
    navigate("/roleplay");
    window.location.reload();
    setUser();
    setOrganization();
    resetAll();
  };
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <div className="navbar-brand-logo">
          <Link to="/roleplay">
            <img className="logo-img-bc" src={logo} alt="" />
          </Link>
        </div>
        <div className="nam-area-ley-">
          <div>
            <img src={organization ? organization.logo : temp} alt="" />
            <div>
              <p>{organization?.business_name}</p>
              <b>{user?.name}</b>
            </div>
          </div>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomLeft"
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <BsThreeDotsVertical />
            </span>
          </Dropdown>
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[window.location.pathname]}
          selectedKeys={[window.location.pathname]}
          items={[
            {
              key: "/roleplay",
              icon: <img className="logo-img-bc" src={roleplay} alt="" />,
              label: "Role Play",
              onClick: () => navigate("/roleplay"),
            },
            // {
            //   key: "/applications",
            //   icon: <img className="logo-img-bc" src={applications} alt="" />,
            //   label: "Applications",
            //   onClick: () => navigate("/applications"),
            // },
            // {
            //   key: "/functions",
            //   icon: <img className="logo-img-bc" src={Functions} alt="" />,
            //   label: "Functions",
            //   onClick: () => navigate("/functions"),
            // },
            // {
            //   key: "/elements",
            //   icon: <img className="logo-img-bc" src={Elements} alt="" />,
            //   label: "Elements",
            //   onClick: () => navigate("/elements"),
            // },
            {
              key: "/simulation",
              icon: <img className="logo-img-bc" src={playground} alt="" />,
              label: "Simulation",
              onClick: () => navigate("/simulation"),
            },
            {
              key: "/playground",
              icon: <img className="logo-img-bc" src={playground} alt="" />,
              label: "Playground",
              onClick: () => navigate("/playground"),
            },
            {
              key: "/activity",
              icon: <img className="logo-img-bc" src={activity} alt="" />,
              label: "Activity",
              onClick: () => navigate("/activity"),
            },
            {
              key: "/account",
              icon: <img className="logo-img-bc" src={account} alt="" />,
              label: "Account",
              onClick: () => navigate("/account"),
            },
            {
              key: "/developers",
              icon: <img className="logo-img-bc" src={developers} alt="" />,
              label: "Developers",
              onClick: () => navigate("/developers"),
            },
            {
              key: "/dialogue",
              icon: <img className="logo-img-bc" src={dialogue} alt="" />,
              label: "Dialogue",
              onClick: () => navigate("/dialogue"),
            },
            {
              key: "/billing",
              icon: <img className="logo-img-bc" src={dialogue} alt="" />,
              label: "Billing",
              onClick: () => navigate("/billing"),
            },
         
          ]}
        />
      </Sider>
      <Layout>
        {window.location.pathname === "/applications" ? (
          <ApplicationHeader />
        ) : null}
        {window.location.pathname === "/simulation" ? <SimulationHeader /> : null}
        {window.location.pathname === "/elements" ? <ElementsHeader /> : null}
        {window.location.pathname === "/account" ? <AccountsHeader /> : null}
        {window.location.pathname === "/playground" ? (
          <PlaydroundHeader />
        ) : null}
        {window.location.pathname === "/functions" ? <FunctionsHeader /> : null}
        {window.location.pathname === "/roleplay" ? <RoleplayHeader /> : null}
        {window.location.pathname.includes("/application/") ? (
          <GeneratedHeader />
        ) : null}
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <div className="page-bx-main-app">{page}</div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MyLayout;
