import React, { useContext } from "react";
import MyAccount from "../../components/account/components/MyAccount";
import MyOrganization from "../../components/account/components/MyOrganization";
import MyTeam from "../../components/team/MyTeam";
import { Alert } from "antd";
import SearchContext from "../../Context/SearchContext";
import Header from "../../components/Header";
import AccountsHeader from "./components/AccountsHeader";
import Billing from "../billing/Billing";

export default function Accounts() {
  const { tasks, setTasks } = useContext(SearchContext);
  return (
    <div>
      <AccountsHeader />
      {tasks.disAccount === undefined || tasks.disAccount === "account" ? (
        <MyAccount />
      ) : null}
      {tasks.disAccount === "Organization" ? <MyOrganization /> : null}
      {tasks.disAccount === "Team" ? <MyTeam /> : null}
      {tasks.disAccount === "Billing" ? <Billing /> : null}
    </div>
  );
}
