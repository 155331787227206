import React, { useEffect, useState } from "react";

import { Col, Row, Spin, Tag } from "antd";

import { FaArrowDown, FaArrowRight, FaPlay } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";
import FullLoader from "../../components/FullLoader";
import ReactPlayer from "react-player";
import "./public.css";
import logo from "../../images/Telemit.png";

import { Link, useNavigate } from "react-router-dom";
export default function Public() {
  const navigate = useNavigate();
  const [gamesData, setGamesData] = useState({ pageNo: 1 });
  const [loading, setLoadeing] = useState(true);

  const [callApi, setCallApi] = useState(1);

  useEffect(() => {
    if (gamesData.publicData === undefined || callApi > 1) {
      getlist();
    } else {
      setLoadeing(false);
    }
  }, [callApi]);

  const getlist = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=${gamesData.pageNo}&limit=12&access=public`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          let all = [];
          if (gamesData.pageNo && gamesData.pageNo > 1) {
            all = gamesData.publicData.concat(data.feedList);
          } else {
            all = [...data.feedList];
          }
          all = all?.map((obj) => ({ ...obj, key: obj._id }));

          setGamesData({
            ...gamesData,
            publicData: all,

            totalPages: data.totalPages,
          });
        }

        setLoadeing(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  return (
    <div>
      <div className="public-page-c">
        <div style={{ padding: "20px" }} className="landing-logo-no-u">
          <Link to={"/"}>
            <img className="logo-img-bc" src={logo} alt="" />
          </Link>
          <div>
            <b className="pricing-b-c">
              <Link to={"/community"}>Public</Link>
            </b>
            <b className="pricing-b-c">
              <Link to={"/pricing"}>Pricing</Link>
            </b>
            <Link to={"/login"}>
              <button className="my-lbutton">Login</button>
            </Link>
          </div>
        </div>
        <div className="container">
          <h4 className="gradient-text-title" style={{ textAlign: "center" }}>
            {" "}
            Public Telemissions
          </h4>
          <p style={{ textAlign: "center" , color:"white" }}>A collection of AI agents deployed by our community</p>

          {gamesData.publicData === undefined ? (
            <div className="box-loading">
              <FullLoader />
            </div>
          ) : null}

          <Row>
            {gamesData.publicData &&
              gamesData.publicData.map((m, mi) => {
                return (
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                    key={mi}
                    //   className="display-goal-st-s"
                    style={{ padding: "15px" }}
                  >
                    <div className="public-video-card">
                      <img src={m.thumbnail} alt="" />

                      <div className="public-top-c">
                        <b>{m.title}</b>
                      </div>
                      <div className="public-top-c2">{m.instruction}</div>
                      <div className="bottom-right-c">
                        <div
                          onClick={() => navigate(`/community/${m._id}`)}
                          className="right-arrow-public"
                        >
                          <FaPlay />
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>

          {gamesData.totalPages && gamesData.totalPages > gamesData.pageNo ? (
            <div className="load-more-btn">
              {loading ? (
                <Spin indicator={antIcon} />
              ) : (
                <Tag
                  onClick={() => {
                    setGamesData({
                      ...gamesData,
                      pageNo: gamesData.pageNo + 1,
                    });
                    setLoadeing(true);
                    setCallApi(callApi + 1);
                  }}
                  className={`editor-tag-tab  active-tg-wdt`}
                >
                  Load More <FaArrowDown />
                </Tag>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
