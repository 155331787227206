import React, { useEffect, useState } from "react";
import "./BackgroundVideo.css";
import { Link } from "react-router-dom";
import logo from "../../images/Telemit.png";
import ReactPlayer from "react-player";

import Paragraph from "antd/es/typography/Paragraph";
import { motion, AnimatePresence } from "framer-motion";
import { FaPause, FaPlay, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import MobileHamburger from "./component/MobileHamburger";
import { Dropdown, Menu, Select, Space } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
const { Option } = Select;
function LandingPage() {
  const isMobile = useMediaQuery({ maxWidth: 680 });
  const [show, setShow] = useState(false);
  const [play, setPlay] = useState(true);
  const [mute, setMute] = useState(true);
  const [loading, setLoading] = useState(true);
  const [callClose, setCallClose] = useState(1);
  const [videos, setvideos] = useState();
  const [active, setActive] = useState();
  const [showItems, setShowItems] = useState(false);

  useEffect(() => {
    // Set showItems to true after component mounts to trigger animations
    setShowItems(true);
  }, []);
  useEffect(() => {
    if (callClose > 1) {
      setShow(false);
    }
  }, [callClose]);
  useEffect(() => {
    if (videos === undefined) {
      getVideos();
    }
  }, []);

  const getVideos = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?status=${isMobile ? "mobile" : "review"}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setvideos(data.feedList);
          if (data.feedList && data.feedList.length) {
            setActive(data.feedList[0]);
          }
        }
      });
  };

  const bottomRight = () => {
    return (
      <div className="bottom-right-pop hide-mobile">
        <div className="testing-animation-cads1">
          {/* <AnimatePresence initial={false}>
            {showItems && (
              <motion.div
                initial={{ x: "100%" }} 
                animate={{ x: 0 }}
                exit={{ x: "-100%" }} 
                transition={{ duration: 0.5 }}
              ></motion.div>
            )}
          </AnimatePresence> */}

          <motion.div
            initial={{ opacity: 0, y: 150 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 10 }}
            className="flex"
          >
            {videos &&
              videos.map((v, vi) => {
                return (
                  <div
                    className={`card-vd-top-m ${
                      v._id === active?._id ? " active-video-landing" : ""
                    }`}
                    key={vi}
                    onClick={() => {
                      setActive(v);
                    }}
                  >
                    <img src={v.thumbnail} alt="" />
                    <div>
                      <Paragraph
                        ellipsis={{
                          rows: 1,
                          // expandable: true,
                          symbol: "see more",
                        }}
                        style={{
                          marginBottom: "0px",
                          fontSize: "11px",
                          marginTop: "10px",
                          textAlign: "center",
                        }}
                        className="desc-p-crdjjkl"
                      >
                        {v.description}
                      </Paragraph>
                    </div>
                  </div>
                );
              })}
          </motion.div>
        </div>
      </div>
    );
  };
  const bottomRightMobile = () => {
    return (
      <motion.div
        style={{
          marginBottom: "10px",
          zIndex: 20,
        }}
        initial={{ opacity: 0, y: 150 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 10 }}
        className="flex bottom-right-pop show-mobile"
      >
        {videos &&
          videos
            .filter((f) => f._id !== active?._id)
            .map((v, vi) => {
              return (
                <div
                  className={`card-vd-top-m ${
                    v._id === active?._id ? " active-video-landing" : ""
                  }`}
                  key={vi}
                  onClick={() => {
                    setActive(v);
                  }}
                  style={{ marginBottom: "0px" }}
                >
                  <img src={v.thumbnail} alt="" />{" "}
                  {v.description ? (
                    <div>
                      <Paragraph
                        ellipsis={{
                          rows: 1,
                          // expandable: true,
                          symbol: "see more",
                        }}
                        style={{
                          marginBottom: "0px",
                          fontSize: "10px",
                          marginTop: "10px",
                          textAlign: "center",
                        }}
                        className="desc-p-crdjjkl"
                      >
                        {v.description}
                      </Paragraph>
                    </div>
                  ) : null}
                </div>
              );
            })}
      </motion.div>
    );
  };
  const overlayTextMobile = () => {
    return (
      <div className="my-llanding-page2 show-mobile popup-center-text">
        <h1 className="my-lheading">
          A New Way To
          <br /> Communicate
        </h1>

        <div className="my-lbuttons">
          <button onClick={() => setShow(true)} class="ui-uiverse">
            <div class="ui-wrapper">
              <span> Watch</span>
              <div class="ui-circle circle-12"></div>
              <div class="ui-circle circle-11"></div>
              <div class="ui-circle circle-10"></div>
              <div class="ui-circle circle-9"></div>
              <div class="ui-circle circle-8"></div>
              <div class="ui-circle circle-7"></div>
              <div class="ui-circle circle-6"></div>
              <div class="ui-circle circle-5"></div>
              <div class="ui-circle circle-4"></div>
              <div class="ui-circle circle-3"></div>
              <div class="ui-circle circle-2"></div>
              <div class="ui-circle circle-1"></div>
            </div>
          </button>
        </div>
      </div>
    );
  };

  const centerCards = () => {
    return (
      <div className="testing-animation-cads hide-mobile">
        <motion.div
          initial={{ opacity: 0, x: 150 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 7 }}
          className={`card-vd-top-m no-bg-card`}
        >
          <h5>Scale Your Testing, Save Your Time</h5>
          <div className="text-sixe0card">
            Automate the tedious parts of testing with deeper insights. Focus
            your team on what matters – building great products.
          </div>
        </motion.div>
      </div>
    );
  };
  const items = [
    {
      key: "1",
      label: (
        <a target="blank" href="https://help.telemit.com/product/live">
          Live
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="blank" href="https://help.telemit.com/product/observe">
          Observe
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a target="blank" href="https://help.telemit.com/product/generate">
          Generate
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a target="blank" href="https://help.telemit.com/product/dendric">
          Dendric
        </a>
      ),
    },
  ];
  const items2 = [
    { key: "11", label: "Gaming", link: "https://help.telemit.com/use-case/gaming" },
    { key: "21", label: "Research", link: "https://help.telemit.com/use-case/research" },
    { key: "31", label: "Automation", link: "https://help.telemit.com/use-case/automation" },
    { key: "41", label: "Creativity", link: "https://help.telemit.com/use-case/creativity" },
  ];

  const menu2 = (
    <Menu>
      {items2.map((item) => (
        <Menu.Item key={item.key}>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {item.label}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div style={{ minHeight: "100px", height: "100vh" }} className="">
      <MobileHamburger />
      {loading ? (
        <div className="loader-video-img ">
          <img src={logo} alt="" />
        </div>
      ) : null}
      <div className="full-height video-container full-width  video-container-round">
        {active ? (
          <ReactPlayer
            loop={false}
            url={active.file_url}
            playing={play}
            muted={mute}
            controls={false}
            onPlay={() => setLoading(false)}
          />
        ) : null}
      </div>

      <div className="landing-logo-no-u ">
        <Link className="card-logo-top-l" to={"/"}>
          <img className="logo-img-bc" src={logo} alt="" />
        </Link>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="landing-logo-no-10 hide-mobile"
        >
          <Dropdown
            menu={{
              items,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Product
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <a
            target="blank"
            style={{ marginRight: "15px" }}
            href={"https://help.telemit.com/"}
          >
            <b className="my-lbutton-10">Guide</b>
          </a>

          <Dropdown overlay={menu2}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <Space>
                Use Cases <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <Link to={"/pricing"}>
            <b className="my-lbutton-10">Pricing</b>
          </Link>
          <a
            target="blank"
            style={{ margin: "15px" }}
            href={"https://help.telemit.com/company"}
          >
            <b className="my-lbutton-10">Company</b>
          </a>

          <a
            style={{ margin: "15px" }}
            href="https://calendly.com/paulw-zhrz/30min"
            target="blank"
          >
            <button className="my-lbutton">Request Access</button>
          </a>

          <Link to={"/"}>
            <button className="my-lbutton">Login</button>
          </Link>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 7 }}
        style={{
          justifyContent: "flex-start",
          bottom: "10px",
          left: "10px",
        }}
        className="bottom-left-popup"
      >
        {play ? (
          <button
            onClick={() => {
              setPlay(!play);
            }}
            className="my-lbutton"
          >
            <FaPause />
          </button>
        ) : (
          <button
            onClick={() => {
              setPlay(!play);
            }}
            className="my-lbutton"
          >
            <FaPlay />
          </button>
        )}
        {mute ? (
          <button
            onClick={() => {
              setMute(!mute);
            }}
            className="my-lbutton"
          >
            <FaVolumeMute />
          </button>
        ) : (
          <button
            onClick={() => {
              setMute(!mute);
            }}
            className="my-lbutton"
          >
            <FaVolumeUp />
          </button>
        )}
      </motion.div>
      {centerCards()}
      {bottomRight()}
      {bottomRightMobile()}
      {overlayTextMobile()}
    </div>
  );
}

export default LandingPage;
