import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Slider, DatePicker, InputNumber, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FaInfoCircle } from "react-icons/fa";
import FullLoader from "../../components/FullLoader";

export default function OnboardFeed() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [step, setStep] = useState(1);
  const [oldData, setOldData] = useState();

  useEffect(() => {
    getfeedPlay(window.location.pathname.slice(9));
  }, [pageNo]);

  const getfeedPlay = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=${pageNo}&limit=1&temp_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          if (data.feedList && data.feedList.length) {
            setOldData(data.feedList[0]);
          } else {
            navigate("/");
          }
        }

        setLoading(false);
      });
  };

  const adjustTime = (t) => {
    const newDate = new Date();
    newDate.setMinutes(newDate.getMinutes() + 20);
    let t2 = Number(t) + 20;

    const endDate = new Date();
    endDate.setMinutes(endDate.getMinutes() + t2);
    console.log(newDate, endDate);
    setOldData({
      ...oldData,
      date: newDate,
      since: newDate,
      till: endDate,
      time_length: t,
    });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  const updaePlay = (obj, id) => {
    delete obj._id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setLoading();
        if (response && response.isPatched) {
          setOldData(response.patchedData);
          setStep(2);
        } else {
          message.error("somehing went wrong");
        }
      });
  };

  const payAlert = () => {
    return (
      <div class="notifications-container">
        <div class="dad-joke">
          <div class="flex">
            <div class="flex-shrink-0">
              <FaInfoCircle class="dad-joke-svg" />
            </div>
            <div class="dad-joke-prompt-wrap">
              <p class="dad-joke-prompt-heading">Dad Jokes Alert!</p>
              <div class="dad-joke-prompt">
                <p>
                  Why don’t scientists trust atoms? Because they make up
                  everything.
                </p>
              </div>
              <div class="dad-joke-button-container flex-end">
                <a
                  style={{ textDecoration: "none" }}
                  target="blank"
                  href={oldData?.checkout}
                >
                  <button class="dad-joke-button-main" type="button">
                    Pay Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {" "}
      <div className="container">
        {oldData ? (
          <div className="play-f-public">
            <h5 style={{ textAlign: "center" }}>
              Onboarding For {oldData.name}
            </h5>

            {step === 1 ? (
              <div style={{ minHeight: "500px" }} className=" form-pf">
                <div className="game-show-p1">
                  <div>
                    <div>
                      <h3 style={{ marginTop: "20px" }}>Hi {oldData.name}</h3>
                      <div>
                        <label
                          style={{ margin: "20px 0px" }}
                          for="name"
                          class="label-pf"
                        >
                          <span class="title-pf">Name *</span>
                          <input
                            placeholder="Name"
                            onChange={(e) => {
                              setOldData({
                                ...oldData,
                                title: e.target.value,
                              });
                            }}
                            value={oldData.title}
                            style={{ width: "100%" }}
                            class="input-field-pf"
                          />
                        </label>

                        <div
                          className="together-fields-c"
                          style={{ margin: "20px 0px" }}
                        >
                          <label
                            style={{ width: "100%", marginRight: "10px" }}
                            for="name"
                            class="label-pf"
                          >
                            <span class="title-pf"> Link</span>

                            <input
                              placeholder=" Link"
                              onChange={(e) => {
                                setOldData({
                                  ...oldData,
                                  link: e.target.value,
                                });
                              }}
                              class="input-field-pf"
                              value={oldData.link}
                              size="large"
                            />
                          </label>

                          <label for="surfa ce" class="label-pf">
                            <span class="title-pf">Surface</span>
                            <select
                              class="input-field-pf"
                              onChange={(e) => {
                                setOldData({
                                  ...oldData,
                                  channelUuid: e.target.value,
                                });
                              }}
                              value={oldData.channelUuid}
                            >
                              <option disabled value="">
                                Select Surface
                              </option>

                              <option value="chrome">Chrome</option>
                              <option value="edge">Edge</option>
                              <option value="android">Android</option>
                              <option value="firefox">Firefox</option>
                              <option value="safari">Safari</option>
                              <option value="apple">Apple</option>
                              <option value="windows">Windows</option>
                            </select>
                          </label>
                        </div>

                        <div>
                          <label
                            style={{ margin: "20px 0px" }}
                            for="name"
                            class="label-pf"
                          >
                            <span class="title-pf">Instruction *</span>
                            <Input.TextArea
                              autoSize={{ minRows: 2, maxRows: 4 }}
                              placeholder="Instruction"
                              onChange={(e) => {
                                setOldData({
                                  ...oldData,
                                  instruction: e.target.value,
                                });
                              }}
                              value={oldData.instruction}
                              style={{ width: "100%" }}
                              size="large"
                            />
                          </label>

                          <div
                            className="together-fields-c"
                            style={{ margin: "20px 0px" }}
                          >
                            <label
                              style={{ width: "100%" }}
                              for="time"
                              class="label-pf"
                            >
                              <span class="title-pf">Time *</span>
                              <div className="biling-m-ci1">
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="biling-m-c1 my-sli-play-f"
                                >
                                  <Slider
                                    min={15}
                                    max={420}
                                    onChange={adjustTime}
                                    value={oldData.time_length}
                                    style={{ width: "100%" }}
                                  />
                                  <b>{oldData.time_length} Minutes</b>
                                </div>
                                {/* <div className="bottom-txt-sli">
                            <b>15</b>
                            <b>420 min</b>
                          </div> */}
                              </div>
                            </label>
                          </div>

                          {oldData.session === "multi_session" ||
                          oldData.session === "longitudinal_study" ? (
                            <div>
                              <div className="date-session-c">
                                <div>
                                  <span style={{ marginRight: "10px" }}>
                                    Start
                                  </span>
                                  <DatePicker
                                    format="MM-DD-YYYY"
                                    value={oldData.multi_start}
                                    onChange={(e) =>
                                      setOldData({
                                        ...oldData,
                                        multi_start: e,
                                      })
                                    }
                                  />
                                </div>
                                <div>
                                  <span style={{ marginRight: "10px" }}>
                                    End
                                  </span>
                                  <DatePicker
                                    format="MM-DD-YYYY"
                                    value={oldData.multi_end}
                                    onChange={(e) =>
                                      setOldData({
                                        ...oldData,
                                        multi_end: e,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="date-session-c2">
                                <span style={{ marginRight: "10px" }}>
                                  Days
                                </span>
                                <InputNumber
                                  min={2}
                                  max={365}
                                  value={oldData.multi_days}
                                  onChange={(e) => {
                                    setOldData({
                                      ...oldData,
                                      multi_days: e,
                                    });
                                  }}
                                  placeholder="Days"
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="space-b-btns">
                      <div />

                      <div className="save-btn-pl">
                        {loading ? (
                          <Spin indicator={antIcon} />
                        ) : oldData.title &&
                          oldData.title.length &&
                          oldData.instruction &&
                          oldData.instruction.length &&
                          oldData.time_length ? (
                          <div
                            onClick={() => {
                              // setloading(true);
                              // setOldData({
                              //   ...gamesData,
                              //   showPlay: undefined,
                              // });
                              setLoading(true);
                              updaePlay(oldData, oldData._id);
                            }}
                            className="hover-card-b"
                            style={{
                              maxWidth: "300px",
                            }}
                          >
                            Next
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {step === 2 ? (
              <div className="pay-btn-cs">{payAlert(oldData)}</div>
            ) : null}
          </div>
        ) : <FullLoader />}
      </div>
    </div>
  );
}
