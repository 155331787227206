import React, { useContext } from "react";
import logo from "../../images/Telemit.png";
import SearchContext from "../../Context/SearchContext";
import "./vision.css";
import PlayForm from "../simulation/components/PlayForm";
import Accounts from "../account/Accounts";
import AdvisorsData from "../live/components/AdvisorsData";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { MdAccountCircle, MdOutlineKeyboardArrowDown } from "react-icons/md";
import Landing from "../landing/Landing";
import Memory from "./tabs/Memory";
import Telemission from "./tabs/Telemission";
import EditForm from "./tabs/EditForm";
import { FaDatabase, FaPlus } from "react-icons/fa";
import { BiSolidVideoRecording } from "react-icons/bi";

export default function Vision() {
  const { setGamesData, gamesData, user, setUser, setOrganization } =
    useContext(SearchContext);
  const navigate = useNavigate();
  const items = [
    {
      key: "2",
      label: <span onClick={() => logout()}>Log Out</span>,
    },
  ];
  const logout = () => {
    window.localStorage.removeItem("user");

    window.location.reload();
    setUser();
    setOrganization();
  };
  const VisionTop = () => {
    return (
      <div className="vision-top">
        <Link className="navbar-brand" to="/">
          <img className="logo-img-bc" src={logo} alt="" />
        </Link>
        <div className="top-header-icns">
          <div
            className={`hover-card-b ${
              gamesData.tabType === "initialize" ? " selected-crd" : ""
            } `}
            onClick={() => {
              setGamesData({
                ...gamesData,
                gameCard: "browser",
                showPlay: {
                  step: 1,
                  session: "single_session",

                  access: "private",
                  demographic_country: "united_states",
                },
                tabType: "initialize",
              });
            }}
          >
            <FaPlus />
          </div>
          <div
            className={`hover-card-b ${
              gamesData.tabType === "live" ? " selected-crd" : ""
            } `}
            onClick={() => {
              setGamesData({
                ...gamesData,
                tabType: "live",
              });
            }}
          >
            <BiSolidVideoRecording />
          </div>
          <div
            className={`hover-card-b ${
              gamesData.tabType === "account" ? " selected-crd" : ""
            } `}
            onClick={() => {
              setGamesData({
                ...gamesData,
                tabType: "account",
              });
            }}
          >
            <MdAccountCircle />
          </div>
          <div
            className={`hover-card-b ${
              gamesData.tabType === "data" ? " selected-crd" : ""
            } `}
            onClick={() => {
              setGamesData({
                ...gamesData,
                tabType: "data",
              });
            }}
          >
            <FaDatabase />
          </div>
        </div>
        {user?.id ? (
          <div className="roadm-t-div">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
            >
              <span>
                {user?.name} <MdOutlineKeyboardArrowDown />
              </span>
            </Dropdown>
          </div>
        ) : null}
      </div>
    );
  };
  const VisionLeft = () => {
    return (
      <div className="vision-left">
        <div className="vision-left2">
          <Link className="navbar-brand" to="/">
            <img className="logo-img-bc" src={logo} alt="" />
          </Link>
          <div>
            <div
              className={`hover-card-b ${
                gamesData.tabType === "initialize" ? " selected-crd" : ""
              } `}
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  gameCard: "browser",
                  showPlay: {
                    step: 1,
                    session: "single_session",

                    access: "private",
                    demographic_country: "united_states",
                  },
                  tabType: "initialize",
                });
              }}
            >
              Deploy
            </div>
            <div
              className={`hover-card-b ${
                gamesData.tabType === "live" ? " selected-crd" : ""
              } `}
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  tabType: "live",
                });
              }}
            >
              Telemission
            </div>

            <div
              className={`hover-card-b ${
                gamesData.tabType === "account" ? " selected-crd" : ""
              } `}
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  tabType: "account",
                });
              }}
            >
              Account
            </div>
            <div
              className={`hover-card-b ${
                gamesData.tabType === "data" ? " selected-crd" : ""
              } `}
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  tabType: "data",
                });
              }}
            >
              Data
            </div>
            <div
              className={`hover-card-b  `}
              onClick={() => navigate("/community")}
            >
              Community
            </div>
          </div>
        </div>
        {user?.id ? (
          <div className="roadm-t-div">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
            >
              <span>
                {user?.name} <MdOutlineKeyboardArrowDown />
              </span>
            </Dropdown>
          </div>
        ) : null}
      </div>
    );
  };
  return user && user.id ? (
    <div className="vision-page-con">
      {VisionTop()}
      {/*  vision-left be hidden in mobile  */}
      {VisionLeft()}
      <div className="vision-right-bx">
        <div className="vision-right-bx2">
          {gamesData.tabType === "initialize" ? <PlayForm /> : null}
          {gamesData.tabType === "account" ? <Accounts /> : null}
          {gamesData.tabType === "data" ? <AdvisorsData /> : null}
          {gamesData.tabType === "live" ? <Telemission /> : null}
          {gamesData.tabType === "edit" ? <EditForm /> : null}
        </div>
      </div>
    </div>
  ) : (
    <Landing />
  );
}
