import React, { useContext, useEffect, useState } from "react";

import { Row, Spin, Tag } from "antd";

import { useNavigate } from "react-router-dom";
import { FaArrowDown, FaArrowRight } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

import SearchContext from "../../Context/SearchContext";
import FullLoader from "../../components/FullLoader";

export default function UxTab() {
  const { user } = useContext(SearchContext);

  const navigate = useNavigate();
  const [advisorList, setAdvisor] = useState({ pageNoA: 1 });
  const [loading, setLoadeing] = useState(true);

  const [callApi, setCallApi] = useState(1);

  useEffect(() => {
    if (advisorList.activeData === undefined || callApi > 1) {
      getAdvisor(window.location.pathname.slice(23));
    } else {
      setLoadeing(false);
    }
  }, [callApi]);

  const getAdvisor = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/advisor?page=${
        advisorList.pageNoA
      }&limit=20&sort_by=${-1}&roleplay_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          let all = [];
          if (advisorList.pageNoA && advisorList.pageNoA > 1) {
            all = advisorList.activeData.concat(data.advisorList);
          } else {
            all = [...data.advisorList];
          }
          setAdvisor({
            ...advisorList,
            activeData: all?.map((obj) => ({ ...obj, key: obj._id })),
            totalPagesA: data.totalPages,
          });
          if (advisorList.pageNoA === 1) {
            setTimeout(() => {
              setCallApi(callApi + 1);
            }, 5000);
          }
        }

        setLoadeing(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  return (
    <div>
      <div style={{ width: "100%" }} className="active-goals-tc256">
        
          {advisorList.activeData === undefined ? (
            <div className="box-loading">
              <FullLoader />
            </div>
          ) : null}

          <Row>
            {advisorList.activeData &&
              advisorList.activeData.map((m, mi) => {
                return (
                  <div className="advertize-con" key={mi}>
                    {m.content}
                  </div>
                );
              })}
          </Row>

          {advisorList.totalPagesA &&
          advisorList.totalPagesA > advisorList.pageNoA ? (
            <div className="load-more-btn">
              {loading ? (
                <Spin indicator={antIcon} />
              ) : (
                <Tag
                  onClick={() => {
                    setAdvisor({
                      ...advisorList,
                      pageNoA: advisorList.pageNoA + 1,
                    });
                    setLoadeing(true);
                    setCallApi(callApi + 1);
                  }}
                  className={`editor-tag-tab  active-tg-wdt`}
                >
                  Load More <FaArrowDown />
                </Tag>
              )}
            </div>
          ) : null}
        </div>
 
    </div>
  );
}
